<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- แสดงภาพ -->
    <Photo v-if="ShowPhoto" :showphoto="ShowPhoto" :showphotolink="ShowPhotoLink" @emitshowphoto="ClosePhoto" />

    <!-- ปริ้น Barcode -->
    <ReviewPrintbarcode v-if="this.printbarcodeitem.dialog" :dialog="printbarcodeitem.dialog"
      :item="printbarcodeitem.item" @emitprintbarcode="CPrintBarcode" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-bottom-30">
      <v-card-title class="body-1">
        <v-row>
          <v-col col="6">
            สต๊อก
            <v-icon>mdi-chevron-right</v-icon>
            <a @click="toBack('transfer-list')"> นำเข้า/นำออก/โอนย้ายสินค้า </a>
            <v-icon>mdi-chevron-right</v-icon>
            {{ navigete }}
          </v-col>
          <v-col col="6" align="right">
            <vue-excel-xlsx id="GetExcel" v-show="false" :data="datasetExcel" :columns="headerExcel"
              :file-name="ExcelFile" :sheet-name="'shee1'">
            </vue-excel-xlsx>
            <v-btn :elevation="1" outlined color="success" class="pa-2 ml-2 mt-1" @click="ExportExcel()">
              <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
              <span class="fn-12"> Export Excel </span>
              <span v-if="loadingExcel != 0">({{ loadingExcel }}%)</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>

    <!-- เลือกสินค้า -->
    <v-dialog v-model="addStockPopup.dialog" scrollable :max-width="addStockPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div>
              <v-radio-group row v-if="
                importExportMaster.transactionType == 2 ||
                importExportMaster.transactionType == 4
              " v-model="IsMarkLot" @change="MarkLotChange()">
                <v-radio :disabled="importExportDetail.length > 0" label="ไม่ระบุล๊อต" value="1"></v-radio>
                <v-radio :disabled="importExportDetail.length > 0" label="ระบุล๊อต" value="2"></v-radio>
              </v-radio-group>
            </div>
            <div class="pa-2">
              <v-select style="width: 150px" v-model="SearchBy" label="ค้นหาโดย"
                :items="['SKU', 'ชื่อสินค้า', 'Barcode', 'รหัสสินค้า']" outlined dense hide-details></v-select>
            </div>
            <div class="v-list-item__content pa-2">
              <v-text-field ref="refSearchStockModelPopup" :value="searchStockModelPopup" autocomplete="off"
                class="pl-2" dense hide-details @change="(value) => (searchStockModelPopup = Trim_value(value))"
                @keyup.enter="PopupsearchStock(limits, 0)"></v-text-field>
            </div>
            <div class="pa-2">
              <v-btn :elevation="1" color="primary" class="pa-2" dark @click="PopupsearchStock(limits, 0)">
                <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                ค้นหา
              </v-btn>
            </div>
            <div>
              <v-btn icon @click="addStockPopup.dialog = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>
        <v-divider />

        <!-- PC -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-xs-only"
          :style="{ height: addStockPopup.MaxHeight + 'px' }">
          <v-data-table :headers="addStockPopup.header" :items="addStockPopup.dataset" :items-per-page="9999999999"
            hide-default-footer class="elevation-0 packhai-checkbox-select">
            <template v-slot:item.selectProduct="{ item }">
              <v-btn v-if="item.selectProduct" depressed color="primary"
                @click="ProductSelectCheckbox(item, addStockPopup.dataset.indexOf(item))">
                เลือก
              </v-btn>
              <v-btn v-else outlined depressed color="primary"
                @click="ProductSelectCheckbox(item, addStockPopup.dataset.indexOf(item))">
                เลือก
              </v-btn>
            </template>
            <template v-slot:item.no="{ item }">
              {{ addStockPopup.dataset.indexOf(item) + 1 + offsets }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize mt-1" />
              </a>
            </template>
            <template v-slot:item.name="{ item }">
              <!-- name barcode sku productCode prop1Description prop2Description cfCode -->
              <div class="py-1">
                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <b>{{ item.name }}</b>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <span class="color-gray-13">
                      {{ !item.barcode ? "" : "Barcode" + " : " }} {{ item.barcode }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <span class="color-gray-13">
                      {{ !item.productCode ? "" : "รหัสสินค้า" + " : " }}
                      {{ item.productCode }}
                      {{
                        item.productCode &&
                          (item.prop1Description || item.prop2Description)
                          ? ","
                          : ""
                      }}
                      {{
                        !item.prop1Description && !item.prop2Description
                          ? ""
                          : "ลักษณะ" + " : "
                      }}
                      {{ item.prop1Description }} {{ item.prop2Description }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <span class="color-gray-13">{{ !item.cfCode ? "" : "รหัส CF" + " : " }} {{ item.cfCode }}</span>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-slot:item.createdDatetime="{ item }">
              <span class="fn-13">
                {{ formatDate_thai(item.createdDatetime) }}
              </span>
            </template>
            <template v-slot:item.quantityLeft="{ item }">
              {{ formatMoney(item.quantityLeft) }}
            </template>
            <template v-slot:item.costPrice="{ item }">
              {{ formatMoney(item.costPrice) }}
            </template>
            <template v-slot:item.manufactureDateNow="{ item }">
              <span class="fn-13">
                {{ formatDate_thai(item.manufactureDateStart) }}
              </span>
            </template>
            <template v-slot:item.expirationDateNow="{ item }">
              <span class="fn-13">
                {{ formatDate_thai(item.expirationDateStart) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- PC -->

        <!-- Mobile -->
        <v-card-text class="overflow-x-auto pt-1 pb-1 hidden-sm-and-up"
          :style="{ height: addStockPopup.MaxHeight + 'px' }">
          <v-data-iterator :items="addStockPopup.dataset" :items-per-page="9999999999" hide-default-header
            hide-default-footer>
            <template v-slot:default="props">
              <v-row>
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="pa-1">
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item">
                        <a v-if="item.photoLink != null && item.photoLink != ''" @click="OpenPhoto(item.photoLink)"
                          class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile">
                          <img :src="item.photoLink" class="picsize" />
                        </a>
                        <a v-else class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                          style="height: 90px; min-width: 90px; width: 90px">
                          <span class="bold-color-gray-12"> No Picture </span>
                        </a>
                        <div class="v-list-item__content">
                          <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>ชื่อสินค้า</div>
                              <div style="line-height: 1rem !important">
                                : {{ item.name }}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>ลักษณะ</div>
                                <div>
                                  :
                                  {{
                                    (item.prop1Description != null &&
                                      item.prop1Description != ""
                                      ? item.prop1Description
                                      : "") +
                                    (item.prop2Description != null &&
                                      item.prop2Description != ""
                                      ? " , " + item.prop2Description
                                      : "")
                                  }}
                                </div>
                              </div>

                              <br />
                              <div>
                                <div>SKU</div>
                                <div>: {{ item.sku }}</div>
                              </div>

                              <br />
                              <div>
                                <div>Barcode</div>
                                <div>: {{ item.barcode }}</div>
                              </div>

                              <br />
                              <div>
                                <div>รหัสสินค้า</div>
                                <div>
                                  <v-row>
                                    <v-col cols="6" class="pa-0">
                                      : {{ item.productCode }}
                                    </v-col>
                                    <v-col cols="6" class="pa-0" />
                                  </v-row>
                                </div>
                                <v-btn v-if="item.selectProduct" x-small depressed color="primary" @click="
                                  ProductSelect(
                                    item,
                                    addStockPopup.dataset.indexOf(item)
                                  )
                                  ">
                                  เลือก
                                </v-btn>
                                <v-btn v-else outlined x-small depressed color="primary" @click="
                                  ProductSelect(
                                    item,
                                    addStockPopup.dataset.indexOf(item)
                                  )
                                  ">
                                  เลือก
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </v-card-text>
        <!-- Mobile -->

        <div v-if="pageLength > 1 && addStockPopup.dataset.length > 0" class="text-center pa-1 ma-0"
          @click="changePage()">
          <v-pagination v-model="page" :length="pageLength" :total-visible="pageTotalVisible"
            color="error"></v-pagination>
        </div>

        <v-divider />
        <div class="height-57 pa-2" align="right">
          <v-btn :elevation="1" color="primary" class="px-2" @click="Submit_addStockPopup()">
            เพิ่มสินค้า
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- เลือกสินค้า Excel -->
    <v-dialog v-model="addStockExcelPopup.dialog" scrollable :max-width="addStockPopup.MaxWidth" persistent
      :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title class="height-57 pa-0">
          <div class="v-list-item">
            <div class="pa-2">
              <span>
                <h5>เพิ่มสินค้าจาก Excel</h5>
              </span>
            </div>

            <div class="v-list-item__content">
              <v-col cols="12" align="left" style="color: #3d68ff">
                <a href="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/Excel/template_Import_Stock_Excel.xlsx">
                  <h5 style="color: #3d68ff">
                    ดาวน์โหลด template
                    <v-icon left style="color: #3d68ff">mdi-download</v-icon>
                  </h5>
                </a>
              </v-col>
            </div>

            <div>
              <v-btn icon @click="(addStockExcelPopup.dialog = false), $refs.file_excel.reset()"><v-icon
                  color="danger">mdi-close</v-icon></v-btn>
            </div>
          </div>
        </v-card-title>

        <v-card class="overflow-x-auto pt-1 pb-1 hidden-xs-only" :style="{ height: addStockPopup.MaxHeight + 'px' }">
          <v-card-text>
            <table>
              <td>
                <h4>เลือกไฟล์ :</h4>
              </td>
              <td class="pl-2">
                <v-file-input style="width: 500px" v-model="file_excel" ref="file_excel" color="deep-purple accent-4"
                  placeholder="เลือกไฟล์ Excel เท่านั้น" prepend-icon="" outlined hide-details
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  <template v-slot:selection="{ index, text }">
                    <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                      {{ text }}
                    </v-chip>
                    <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2">
                      +{{ files.length - 2 }} file_excel(s)
                    </span>
                  </template>
                </v-file-input>
              </td>
              <td class="pl-2">
                <v-btn color="primary" @click="get_Product_excel()" v-if="!addStockExcelPopup.status">
                  อัพโหลดข้อมูล
                </v-btn>
                <v-btn color="primary" v-else>
                  <h3>ดำเนินการ {{ present_wait }} %</h3>
                </v-btn>
              </td>
            </table>

            <v-divider />

            <v-tabs v-model="tab">
              <v-tabs-slider color="red"></v-tabs-slider>
              <v-tab v-for="i in tab_list" :key="i.id" @click="tab = i.id">
                {{ i.name }} (
                {{
                  i.id == 1
                    ? addStockExcelPopup.dataset.length
                    : i.id == 2
                      ? addStockExcelPopup.dataset_failed.length
                      : addStockExcelPopup.count_null
                }}
                )</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item v-for="i in tab_list" :key="i.id">
                <v-card flat>
                  <v-card-text>
                    <div v-if="i.id == 1">
                      <v-data-table :headers="addStockExcelPopup.headerIn" :items="addStockExcelPopup.dataset"
                        :items-per-page="9999999999" item-key="no" hide-default-footer
                        class="elevation-0 packhai-checkbox-select">
                        <template v-slot:item.no="{ item }">
                          {{ addStockExcelPopup.dataset.indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.photoLink="{ item }">
                          <a v-if="item.photoLink != null && item.photoLink != ''" icon
                            @click="OpenPhoto(item.photoLink)">
                            <img :src="item.photoLink" class="picsize mt-1" />
                          </a>
                        </template>
                        <template v-slot:item.name="{ item }">
                          <v-row>
                            <v-col cols="12" align="left" class="pa-0">
                              <span class="fn-13" style="font-weight: bold">{{
                                item.name
                              }}</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" align="left" class="pa-0">
                              <span class="fn-13">{{ !item.barcode ? "" : "Bacode :" }} {{ item.barcode }}
                                {{ !item.sku ? "" : "SKU :" }} {{ item.sku }}</span>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" align="left" class="pa-0">
                              <span class="fn-13">
                                {{ !item.productCode ? "" : "รหัสสินค้า :" }}
                                {{ item.productCode }}
                                {{
                                  !item.prop1Description && !item.prop2Description
                                    ? ""
                                    : " ลักษณะ :"
                                }}
                                {{ item.prop1Description }} {{ item.prop2Description }}
                              </span>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:item.prop1="{ item }">
                          <span class="fn-13">
                            {{
                              (item.prop1Description != null &&
                                item.prop1Description != ""
                                ? item.prop1Description
                                : "") +
                              (item.prop2Description != null &&
                                item.prop2Description != ""
                                ? " , " + item.prop2Description
                                : "")
                            }}
                          </span>
                        </template>

                        <template v-slot:item.quantityLeft="{ item }">
                          {{ formatMoney(item.quantityLeft) }}
                        </template>
                        <template v-slot:item.costPrice="{ item }">
                          {{ formatMoney(item.costPrice) }}
                        </template>
                      </v-data-table>
                    </div>
                    <div v-if="i.id == 2">
                      <v-data-table :headers="addStockExcelPopup.header_null" :items="addStockExcelPopup.dataset_failed"
                        :items-per-page="9999999999" hide-default-footer class="elevation-0 packhai-checkbox-select">
                      </v-data-table>
                    </div>
                    <div v-if="i.id == 3">
                      <v-data-table :headers="addStockExcelPopup.header_null" :items="addStockExcelPopup.dataset_null"
                        :items-per-page="9999999999" hide-default-footer class="elevation-0 packhai-checkbox-select">
                      </v-data-table>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>

        <v-divider />
        <v-card-actions>
          <v-row>
            <v-col cols="6"> ข้อมูลทั้งหมด {{ addStockExcelPopup.count }} แถว </v-col>
            <v-col cols="6" align="right">
              <v-btn v-if="submittingPercent == 0" :elevation="1" color="primary" @click="Submit_addStockExcelPopup()">
                เพิ่มสินค้า
              </v-btn>
              <v-btn color="primary" v-else>
                <h3>ดำเนินการ {{ submittingPercent }} %</h3>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form ref="formStock" lazy-validation>
      <!-- เลือกประเภท -->
      <v-card elevation="1" class="v-card-bottom-30">
        <v-card-title>
          <v-row>
            <v-col col="6">
              <v-icon size="22" color="teal darken-3">mdi mdi-home-assistant</v-icon><span class="ml-3"> ประเภทรายการ
              </span>
            </v-col>
            <v-col align="right" col="6"> </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row v-if="importExportMaster.id != 0">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span class="color-red">*</span> เลขที่บิล :
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <span class="fn-13">{{ GenIE(importExportMaster.id) }}</span>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row>
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span v-if="importExportMaster.id == 0"><span class="color-red">*</span> เลือกประเภท :
              </span>
              <span v-else><span class="color-red">*</span> ประเภทรายการ : </span>
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-radio-group row v-if="importExportMaster.id == 0" v-model="importExportMaster.transactionType"
                @change="TransactionTypeCheckChanged()">
                <v-radio label="นำเข้า" value="1"></v-radio>
                <v-radio label="นำออก" value="2"></v-radio>
                <v-radio label="ตีกลับ" value="3"></v-radio>
                <v-radio label="โอนย้าย" value="4"></v-radio>
              </v-radio-group>
              <span class="fn-13" v-else>{{ importExportMaster.transactionName }}</span>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row v-if="importExportMaster.transactionType == 2">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span class="color-red">*</span> ออกจากคลัง :
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-select v-if="importExportMaster.id == 0" v-model="warehouse_from_selected" :items="warehouseList"
                @change="warehouse_from_selected_changed()" item-text="name" item-value="id" :disabled="shop.id > 0"
                persistent-hint return-object dense hide-details outlined single-line></v-select>
              <span class="fn-13" v-else>{{ importExportMaster.fromWarehouseName }}</span>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row v-if="
            importExportMaster.transactionType == 1 ||
            importExportMaster.transactionType == 3
          ">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span class="color-red">*</span> เข้าที่คลัง :
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-select v-if="importExportMaster.id == 0" v-model="warehouse_to_selected" :items="warehouseList"
                @change="warehouse_to_selected_changed()" item-text="name" item-value="id" :disabled="shop.id > 0"
                persistent-hint return-object dense hide-details outlined single-line></v-select>
              <span class="fn-13" v-else>{{ importExportMaster.toWarehouseName }}</span>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row v-if="importExportMaster.shopName != null">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span><span class="color-red">*</span> ร้านค้า : </span>
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <span class="fn-13">{{ importExportMaster.shopName }}</span>
            </v-col>
          </v-row>
          <v-row v-else-if="importExportMaster.transactionType == 4">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span> <span class="color-red">*</span> เลือกร้านค้า : </span>
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-autocomplete v-model="shop"   :items="shopList" @change="shop_transfer_changed()"
              item-text="name" item-value="id" persistent-hint return-object dense hide-details
              outlined single-line :disabled="importExportMaster.id > 0"> </v-autocomplete>

             
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row v-else-if="
            warehouse_from_selected.id > 0 ||
            (warehouse_to_selected.id > 0 &&
              importExportMaster.transactionType != 3 &&
              importExportMaster.transactionType > 0)
          ">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span v-if="importExportMaster.id == 0">
                <span class="color-red">*</span> เลือกร้านค้า :
              </span>
              <span v-else><span class="color-red">*</span> ร้านค้า : </span>
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-autocomplete v-if="importExportMaster.id == 0" v-model="shop"   :items="shopList" @change="shop_changed()"
              item-text="name" item-value="id" persistent-hint return-object dense hide-details
              outlined single-line :disabled="importExportMaster.id > 0"> </v-autocomplete>
              <span class="fn-13" v-else>{{ importExportMaster.shopName }}</span>
              <!-- <v-select v-if="importExportMaster.id == 0" v-model="shop" :items="shopList" @change="shop_changed()"
                item-text="name" item-value="id" :disabled="importExportMaster.id > 0" persistent-hint return-object
                dense hide-details outlined single-line></v-select>
              <span class="fn-13" v-else>{{ importExportMaster.shopName }}</span> -->
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row v-if="
            importExportMaster.transactionType == 4 &&
            this.importExportMaster.shopId > 0
          ">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span class="color-red">*</span> ออกจากคลัง :
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-select v-if="importExportMaster.id == 0" v-model="warehouse_from_selected" :items="shopWarehouseList"
                @change="warehouse_from_selected_changed(), Check_Transfer()" item-text="name" item-value="id"
                persistent-hint return-object dense hide-details outlined single-line></v-select>
              <span class="fn-13" v-else>{{ importExportMaster.fromWarehouseName }}</span>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>
          <v-row v-if="
            importExportMaster.transactionType == 4 &&
            this.importExportMaster.shopId > 0 &&
            this.importExportMaster.fromWarehouseId > 0
          ">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span class="color-red">*</span> เข้าที่คลัง :
            </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-select v-if="importExportMaster.id == 0" v-model="warehouse_to_selected" :items="shopWarehouseList"
                @change="warehouse_to_selected_changed(), Check_Transfer()" item-text="name" item-value="id"
                persistent-hint return-object dense hide-details outlined single-line></v-select>
              <span class="fn-13" v-else>{{ importExportMaster.toWarehouseName }}</span>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>

          <v-row v-if="IsReturnValid">
            <v-col cols="4" md="2" class="pt-1 pb-2">
              <span class="color-red">*</span> เลขออเดอร์/เลขแทรคกิ้ง :
            </v-col>
            <v-col cols="5" md="4" class="pt-1 pb-2">
              <v-text-field v-if="importExportMaster.approvedDatetime == null" :value="importExportMaster.referenceInfo"
                @change="(value) => (importExportMaster.referenceInfo = Trim_value(value))
                  " :disabled="IsSendBackdisable" outlined dense hide-details autocomplete="off"></v-text-field>
              <span v-else class="fn-13">{{ importExportMaster.referenceInfo }}</span>
            </v-col>
            <v-col cols="3" md="5">
              <v-row v-if="
                importExportMaster.transactionType == 3 &&
                importExportMaster.approvedDatetime == null &&
                importExportMaster.id == 0
              ">
                <v-col cols="12" class="pt-1 pb-2">
                  <v-btn :elevation="1" color="primary" class="pa-2" dark @click="CreateReturnParcel()">
                    <v-icon size="22" class="pr-1">mdi-magnify</v-icon>
                    ค้นหา
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row v-if="
            IsImportValid ||
            IsExportValid ||
            IsReturnValid ||
            IsTransferValid ||
            IsAdjustValid
          ">
            <v-col cols="4" md="2" class="pt-1 pb-2"> เลขที่อ้างอิง : </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-text-field  
                :value="importExportMaster.referenceNumber" @change="(value) =>
                (importExportMaster.referenceNumber = UpdateStockMaster1(
                  'referenceNumber',
                  value
                ))
                  " outlined dense hide-details autocomplete="off"></v-text-field>
              <!-- <div v-else>
                <span class="fn-13" v-if="
                  importExportMaster.referenceNumber != null &&
                  importExportMaster.referenceNumber != ''
                ">{{ importExportMaster.referenceNumber }}</span>
                <span class="fn-13" v-else> - </span>
              </div> -->
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>
          <v-row v-if="IsImportValid || IsAdjustValid">
            <v-col cols="4" md="2" class="pt-1 pb-2"> ชื่อ Lot : </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-text-field   :value="importExportMaster.lotName"
                @change="(value) =>
                  (importExportMaster.lotName = UpdateStockMaster1('lotName', value))
                  " outlined dense hide-details autocomplete="off"></v-text-field>
              <!-- <div v-else>
                <span class="fn-13" v-if="
                  importExportMaster.lotName != null && importExportMaster.lotName != ''
                ">{{ importExportMaster.lotName }}</span>
                <span class="fn-13" v-else> - </span>
              </div> -->
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>
          <v-row v-if="
            IsImportValid ||
            IsReturnValid ||
            IsExportValid ||
            IsTransferValid ||
            IsAdjustValid
          ">
            <v-col cols="4" md="2" class="pt-1 pb-2"> การอัพเดทสต๊อก : </v-col>
            <v-col cols="8" md="4" class="pt-1 pb-2">
              <v-radio-group row v-if="importExportMaster.approvedDatetime == null"
                v-model="importExportMaster.isUpdateToMarketplace"
                @change="(value) => UpdateStockMaster1('isUpdateToMarketplace', value)" column>
                <v-radio label="อัพเดทจำนวนพร้อมขายทันที" value="true"></v-radio>
                <v-radio label="ยังไม่ต้องอัพเดทจำนวนพร้อมขาย" value="false"></v-radio>
              </v-radio-group>
              <div v-else>
                <span class="fn-13"
                  v-if="importExportMaster.isUpdateToMarketplace.toLowerCase() === 'true'">อัพเดทจำนวนพร้อมขายทันที</span>
                <span class="fn-13" v-else> ยังไม่ต้องอัพเดทจำนวนพร้อมขาย </span>
              </div>
            </v-col>
            <v-col cols="12" md="5" />
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- เช็ค ก่อนแสดง -->
    <v-alert v-if="IsMessage != null" dense border="left" type="warning">
      {{ IsMessage }}
    </v-alert>
    <div v-else-if="
      IsImportValid ||
      IsExportValid ||
      IsTransferValid ||
      IsAdjustValid ||
      (IsReturnValid && importExportMaster.id > 0)
    ">
      <v-row v-if="IsImportValid">
        <v-col cols="4">
          <v-card>
            <v-card-text class="text-center" >
              <span style="font-size: 20px;">{{ importExportDetail.length }}</span><br>
              <span style="color:gray">รายการ</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-text class="text-center" >
              <span v-if="QuantityToReceive>0" style="font-size: 20px;color: orange;"> {{ format_number(QuantityToReceive) }}</span>
              <span v-else style="font-size: 20px;color: orange;">-</span>
              <br>
              <span  style="color:gray">ที่ต้องรับเข้า</span>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-card-text class="text-center"  >
              <span style="font-size: 20px;color: green;">{{ format_number(QuantityReceived) }}</span><br>
              <span  style="color:gray">รับเข้าจริง</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- ข้อมูลสินค้า -->
      <v-card elevation="1" class="v-card-bottom-30 mt-5">
        <v-card-title class="height-57">
          <v-row>
            <v-col cols="6">
              <v-icon size="22" color="red darken-3">mdi mdi-view-list</v-icon><span class="ml-3">รายการสินค้า</span>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn v-if="
                importExportMaster.approvedDatetime == null &&
                importExportMaster.transactionType != 3
              " :elevation="1" outlined color="success" @click="OpenExcelPopup()">
                <v-icon size="22" class="mr-1">mdi-plus</v-icon>
                เพิ่มจาก Excel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text class="overflow-y-auto">
          <div v-if="importExportMaster.approvedDatetime == null">
            <v-row v-if="importExportMaster.transactionType == 1">
              <v-col cols="12">
                <v-radio-group v-model="Bsearch.type" row>
                  <v-radio v-for="i in Bsearch.typeList" color="info" v-bind:key="i['id']" :label="i['name']"
                    :value="i"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pb-4">
                <v-text-field v-model="Bsearch.search" @keyup.enter="SearchBarcode()" @focus="$event.target.select()"
                  ref="refBsearch" label="Barcode" dense hide-details outlined autocomplete="off"></v-text-field>
              </v-col>

              <v-col cols="12" md="5" class="pb-4">
                <v-btn :elevation="1" color="primary" class="pa-2" @click="SearchBarcode()">
                  <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
                  ค้นหา
                </v-btn>

                <v-btn :elevation="1" color="primary" class="pa-2 ml-2" @click="OpenPopup()">
                  <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                  เพิ่มสินค้า
                </v-btn>
              </v-col>

              
            </v-row>
          </div>

         

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :elevation="1" outlined v-bind="attrs" v-on="on">
                <span class="pl-1">ดำเนินการ</span>
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="item in actionMenuList" :key="item.name" @click="handleClickOutside(item)"
                :disabled="isDisabled()">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
          <!-- PC hidden-xs-only -->
          <v-data-table v-model="row_selected" :headers="headerProduct" :items="importExportDetail"
            :items-per-page="9999999999" hide-default-footer show-select item-key="id" item-value="id"
            class="elevation-0 packhai-border-table mt-3">
            <template v-slot:item.success="{ item }">
              <v-icon v-if="item.stockShopId != null" size="22"
                color="success darken-3">mdi-check-circle-outline</v-icon>
              <v-icon v-else size="22" color="red darken-3">mdi-minus-circle-outline</v-icon>
            </template>
            <template v-slot:item.del="{ item }">
              <span v-if="isApproved || item.approved != null" size="16">
                <v-icon size="22" color="success darken-3">mdi-check-circle-outline</v-icon>
              </span>
              <a v-else @click="DeleteProduct(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
            </template>
            <template v-slot:item.printbarcode="{ item }">
              <a @click="OPPrintBarcode(item)">
                <v-icon size="18" color="info darken-3">fa-print</v-icon></a>
            </template>
            <template v-slot:item.no="{ item }">
              <span>
                {{ item.no }}
              </span>
            </template>
            <template v-slot:item.name="{ item }">
              <!-- name barcode sku productCode prop1Description prop2Description cfCode -->
              <div class="py-1">
                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <b>{{ item.name }}</b>
                  </v-col>
                </v-row>

                <v-row v-if="importExportMaster.transactionType != 1">
                  <v-col cols="12" align="left" class="py-0">
                    <span class="color-gray-13">
                      {{ !item.barcode ? "" : "Barcode" + " : " }} {{ item.barcode }}
                      {{ item.barcode && item.sku ? "," : "" }}
                      {{ !item.sku ? "" : "SKU" + " : " }} {{ item.sku }}</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <span class="color-gray-13">
                      {{ !item.productCode ? "" : "รหัสสินค้า" + " : " }}
                      {{ item.productCode }}
                      {{
                        item.productCode &&
                          (item.prop1Description || item.prop2Description)
                          ? ","
                          : ""
                      }}
                      {{
                        !item.prop1Description && !item.prop2Description
                          ? ""
                          : "ลักษณะ" + " : "
                      }}
                      {{ item.prop1Description }} {{ item.prop2Description }}
                    </span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" align="left" class="py-0">
                    <span class="color-gray-13">{{ !item.cfCode ? "" : "รหัส CF" + " : " }} {{ item.cfCode }}</span>
                  </v-col>
                </v-row>
              </div>
            </template>

            <template v-slot:item.unitPrice="{ item }">
              {{ formatMoneyIsDecimal(item.unitPrice) }}
            </template>
            <template v-slot:item.discount="{ item }">
              {{ formatMoneyIsDecimal(item.discount) }}
            </template>
            <template v-slot:item.totalPrice="{ item }">
              {{ formatMoneyIsDecimal(item.quantity * item.unitPrice - item.discount) }}
            </template>
            <template v-slot:item.photoLink="{ item }">
              <a v-if="item.photoLink != null && item.photoLink != ''" icon @click="OpenPhoto(item.photoLink)">
                <img :src="item.photoLink" class="picsize-50 mt-1" />
              </a>
            </template>

            <template v-slot:item.quantity="{ item }">
              <span v-if="item.approved != null">
                {{ formatMoney(item.quantity) }}
              </span>
              <div v-else>
                <v-text-field :value="item.quantity" @change="(value) =>
                  (item.quantity = UpdateStockDetail1(item, 'quantity', value))
                  " @keypress="isNumberWNoDot($event)" :rules="[(v) => (!!v && v != 0) || 'กรุณาระบุ']" dense
                  hide-details autocomplete="off" class="center-input" :ref="item.id" />
              </div>
            </template>
            <template v-slot:item.quantityRequest="{ item }">
              <div v-if="item.quantityRequest != 0">
                {{ formatMoney(item.quantityRequest) }}
              </div>
            </template>
            <template v-slot:item.firstImportDate="{ item }">
              {{ item.firstImportDate }}
            </template>
            <template v-slot:item.createdDatetime="{ item }">
              {{ formatDate_thai(item.createdDatetime) }}
            </template>
            <template v-slot:item.quantityLeft="{ item }">
              {{ formatMoney(item.quantityLeft) }}
            </template>
            <template v-slot:item.barcode="{ item }">
              <v-text-field v-if="importExportMaster.approvedDatetime == null" :value="item.barcode" @change="(value) => (item.barcode = UpdateStockDetail1(item, 'barcode', value))
                " dense hide-details autocomplete="off" />
              <span v-else>
                {{ item.barcode }}
              </span>
            </template>
            <template v-slot:item.rackNo="{ item }">
              <span v-if="
                importExportMaster.approvedDatetime != null ||
                importExportMaster.transactionType == 2
              ">
                {{ item.rackNo }}
              </span>
              <v-text-field v-else-if="location_list.length == 0" :value="item.rackNo" @change="(value) => (item.rackNo = UpdateStockDetail1(item, 'rackNo', value))
                " maxlength="20" dense hide-details autocomplete="off" class="center-input" />
              <v-combobox v-else v-model="item.location_selected" :items="location_list" item-text="locationShortName"
                item-value="locationID" return-object
                @change="(value) => UpdateStockDetail1(item, 'rackNo', value)"></v-combobox>

            </template>
            <template v-slot:item.costPrice="{ item }">
              <span v-if="importExportMaster.approvedDatetime != null">
                {{ formatMoney(item.costPrice) }}
              </span>

              <v-text-field v-else :value="item.costPrice" @change="(value) =>
                (item.costPrice = UpdateStockDetail1(item, 'costPrice', value))
                " @keypress="isNumberWithDot($event)" dense hide-details autocomplete="off" class="center-input" />
            </template>
            <template v-slot:item.manufactureDateNow="{ item }">
              <span v-if="
                item.approved != null ||
                importExportMaster.approvedDatetime != null ||
                importExportMaster.transactionType == 2 ||
                importExportMaster.transactionType == 4
              ">
                {{  item.manufactureDateNow  }}
              </span>

              <v-menu v-else v-model="item.manufactureDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto" max-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="item.manufactureDateNow" readonly hide-details
                    prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" outlined clearable
                    @click:clear="UpdateStockDetail1(item, 'manufactureDateStart', null)"></v-text-field>
                </template>
                <v-date-picker v-model="item.manufactureDateStart" @input="item.manufactureDateMenu = false" @change="
                  item.manufactureDateNow = formatDate(item.manufactureDateStart);
                UpdateStockDetail1(
                  item,
                  'manufactureDateStart',
                  item.manufactureDateStart
                );
                " scrollable no-title></v-date-picker>
              </v-menu>
            </template>
            <template v-slot:item.expirationDateNow="{ item }">
              <span v-if="
                item.approved != null ||
                importExportMaster.approvedDatetime != null ||
                importExportMaster.transactionType == 2 ||
                importExportMaster.transactionType == 4
              ">
                {{  item.expirationDateNow  }}
              </span>

              <v-menu v-else v-model="item.expirationDateMenu" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto" max-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="item.expirationDateNow" readonly hide-details prepend-inner-icon="mdi-calendar"
                    v-bind="attrs" v-on="on" outlined clearable
                    @click:clear="UpdateStockDetail1(item, 'expirationDateStart', null)"></v-text-field>
                </template>
                <v-date-picker v-model="item.expirationDateStart" @input="item.expirationDateMenu = false" @change="
                  item.expirationDateNow = formatDate(item.expirationDateStart);
                UpdateStockDetail1(
                  item,
                  'expirationDateStart',
                  item.expirationDateStart
                );
                " scrollable no-title></v-date-picker>
              </v-menu>
            </template>
            <template v-slot:item.remark="{ item }">
              <span v-if="importExportMaster.approvedDatetime != null">
                {{ item.remark }}
              </span>
              <v-text-field v-else :value="item.remark" @change="(value) => (item.remark = UpdateStockDetail1(item, 'remark', value))
                " dense hide-details autocomplete="off" class="center-input"></v-text-field>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- เอกสาร -->
      <v-card elevation="1" class="v-card-bottom-30" v-if="importExportMaster.id > 0">
        <v-card-title class="height-57">
          <v-icon size="22" color="orange darken-3">mdi mdi-file-document</v-icon><span class="ml-3">เอกสาร</span>
          <v-spacer />
          <v-btn :elevation="1" color="primary" class="pa-2" @click="Add_ImportExportPhoto()" outlined>
            <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
            เพิ่มเอกสาร
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-data-table :mobile-breakpoint="0" :headers="headerDoc" :items="importExportPhoto"
            :items-per-page="9999999999" item-key="keyId" hide-default-footer class="packhai-border-table">
            <template v-slot:item.del="{ item }">
              <a @click="Del_ImportExportPhoto(importExportPhoto.indexOf(item), item)">
                <v-icon size="16" color="red darken-3">fa-trash-alt</v-icon>
              </a>
            </template>
            <template v-slot:item.no="{ item }">
              {{ formatMoney(importExportPhoto.indexOf(item) + 1) }}
            </template>
            <template v-slot:item.fileUrl="{ item }">
              <div v-if="item.fileUrl != null && item.fileUrl != ''">
                <a v-if="CheckUrlPhoto(item.fileUrl) == 1" @click="OpenPhoto(item.fileUrl)">
                  <v-avatar class="picsize-hover" tile>
                    <img :src="item.fileUrl" class="picsize" />
                  </v-avatar>
                </a>
                <a v-else @click="Opentap(item.fileUrl)">
                  <v-avatar class="picsize-hover" tile>
                    <span> File.{{ CheckUrlPhoto(item.fileUrl) }} </span>
                  </v-avatar>
                </a>
              </div>
              <div v-else>
                <v-avatar class="picsize-hover" tile>
                  <span> No File </span>
                </v-avatar>
              </div>
            </template>
            <template v-slot:item.select="{ item }">
              {{ item.fileUpdate.file_name }}
            </template>
            <template v-slot:item.btn="{ item }">
              <div class="btn-addphot">
                <v-btn :elevation="0" class="btn-inputfile" @click="
                  SetIndex(importExportPhoto.indexOf(item)), $refs.inputfile.click()
                  ">
                  <span>อัพโหลไฟล์</span>
                </v-btn>
                <input class="display-none d-none" ref="inputfile" accept="image/*" type="file"
                  @change="previewpicture(indexDoc, $event)" />
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>

      <!-- หมายเหตุ -->
      <v-card elevation="1" class="v-card-bottom-30" v-if="importExportMaster.id > 0">
        <v-card-title class="height-57">
          <v-icon size="22" color="yellow darken-3">mdi mdi-tooltip-text</v-icon><span class="ml-3"> หมายเหตุ </span>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea :value="importExportMaster.remark" @change="(value) =>
                (importExportMaster.remark = UpdateStockMaster1('remark', value))
                " dense hide-details autocomplete="off" outlined rows="2" row-height="15"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- Summit -->
      <v-card elevation="1" v-if="importExportMaster.id > 0">
        <v-card-title class="height-57">
          <div v-if="!savechanges">
            <span v-if="importExportMaster.approvedDatetime != null"><v-chip color="success"> อนุมัติ </v-chip></span>
            <span v-else><v-chip color="warning"> รออนุมัติ </v-chip></span>
          </div>
          <v-spacer />
          <div v-if="importExportMaster.approvedDatetime == null">
            <div v-if="importExportMaster.id == 0">
              <v-btn :elevation="1" color="primary" class="pa-2" @click="Load_Data_1_loadPage(true)">
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-floppy-disk</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </div>
            <div v-else-if="importExportMaster.id > 0 && savechanges">
              <v-btn :elevation="1" color="primary" class="pa-2" @click="CheckSaveChanges()">
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-floppy-disk</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>
            </div>
            <div v-else-if="IsApproveButtonVisible">
              <v-btn :elevation="1" color="primary" class="pa-2" @click="checkBeforeApprove();">
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-check</v-icon>
                <span class="fn-14"><b> อนุมัติ </b></span>
              </v-btn>
            </div>
          </div>
          <div v-else-if="importExportMaster.approvedDatetime != null && savechanges">
            <v-btn :elevation="1" color="primary" class="pa-2" @click="(savechanges = false), SuccessSaveChanges()">
              <v-icon size="14" class="mr-1 pt-1">fa-solid fa-floppy-disk</v-icon>
              <span class="fn-14"><b> บันทึก </b></span>
            </v-btn>
          </div>
        </v-card-title>
      </v-card>
    </div>

    <!-- Approve Progress bar -->
    <v-dialog v-model="approveDialog" scrollable :max-width="approveDialogWidth" persistent>
      <v-card elevation="1">
        <v-card-title class="height-57">
          <v-row>
            <v-col cols="6">
              <span>
                <h5>อนุมัติรายการ</h5>
              </span>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn icon @click="
                (approveDialog = false),
                (approving = false),
                (approvingPercent = 0),
                (isApproveDisabled = false)
                "><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-progress-linear v-model="approvingPercent" color="success" height="25">
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
          <br />
          <v-row>
            <v-col cols="12" align="center">
              <v-btn :elevation="1" color="primary" class="pa-2" :disabled="isApproveDisabled"
                @click="ApprovedImportExport()">
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-check</v-icon>
                <span class="fn-14"><b> อนุมัติ </b></span>
              </v-btn>

              <v-btn :elevation="1" color="error" class="pa-2 ml-4" @click="
                (approveDialog = false),
                (approving = false),
                (approvingPercent = 0),
                (isApproveDisabled = false)
                ">
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-close</v-icon>
                <span class="fn-14"><b> ยกเลิก </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="chooseRackNoDialog" scrollable :max-width="approveDialogWidth" persistent>
      <v-card elevation="1">
        <v-card-title class="height-57">
          <v-row>
            <v-col cols="6">
              <span>
                <h5>ระบุ RackNo</h5>
              </span>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn icon @click=" (chooseRackNoDialog = false)"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>

          <v-row>
            <v-col cols="3" class="pt-3">
              RackNo :
            </v-col>
            <v-col cols="6">
              <v-text-field v-if="location_list.length == 0" v-model="allRackNoInput" maxlength="20" dense hide-details
                autocomplete="off" />
              <v-combobox v-else v-model="all_location_selected" :items="location_list" item-text="locationShortName"
                item-value="locationID" return-object @change="(value) => CheckLocationSelected(value)"></v-combobox>
              <span v-if="alertRackInput" style="color:red">กรุณาระบุ</span>
            </v-col>

            <v-col cols="3" align="right">
              <v-btn :elevation="1" color="primary" class="pa-2" @click="btnSaveRackNoAll()">
                <v-icon size="14" class="mr-1 pt-1">fa-solid fa-save</v-icon>
                <span class="fn-14"><b> บันทึก </b></span>
              </v-btn>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="saveDialog" scrollable :max-width="saveDialogWidth" persistent>
      <v-card elevation="1">
        <v-card-title class="height-57">
          <v-row>
            <v-col cols="6">
              <span>
                <h5>กำลังบันทึก</h5>
              </span>
            </v-col>
            <v-col cols="6" align="right">
              <v-btn icon @click="
                (saveDialog = false),
                (savingPercent = 0)"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-progress-linear v-model="savingPercent" color="success" height="25">
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Photo from "@/website/components/photo";
import ReviewPrintbarcode from "@/website/components/reviewPrintBarcode";
import Loading from "@/website/components/Loading";
import {
  stockService_dotnet,
  shopService_dotnet,
  branchService_dotnet,
  orderService_dotnet,
  generalService_dotnet,
} from "@/website/global";
import {
  AlertSuccess,
  AlertWarning,
  AlertError,
  SendError,
} from "@/website/global_alert";
import {
  formatDate_thai,
  formatDatetime,
  formatDatetime1,
  formatMoney,
  isNumberWithDot,
  isNumberWNoDot,
  DateNowNotNull,
  CheckUrlPhoto,
  GenIE,
  Trim_value,
  Trim_rules,
  ChangeReferenceInfo,
  formatMoneyIsDecimal,
  format_number,
  format_price,
  getLocation,
  get_premission_manage,
  permission_alert
} from "@/website/global_function";
import readXlsxFile from "read-excel-file";
import { log } from "async";
export default {
  components: {
    Photo,
    ReviewPrintbarcode,
    Loading,
  },
  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    BranchID: parseInt(localStorage.getItem('Branch_BranchID')),
    staffPermission: JSON.parse(localStorage.getItem("permission")),
    loadingExcel: 0,
    language: "th",
    SearchBy: "SKU",
    activetab: 1,
    submittingPercent: 0,
    submittingProductFromExcel: false,
    file_excel: [],
    page_loading: true,
    loading: false,
    navigete: null,
    IsApproveButtonVisible: false,
    tab: 1,
    tab_list: [
      { id: 1, name: "ค้นหาสำเร็จ" },
      { id: 2, name: "เกิดข้อผิดพลาด" },
      { id: 3, name: "หาไม่เจอ" },
    ],
    staffBranchID: parseInt(localStorage.getItem("Branch_StaffID")),
    location_list: [],
    location_name_item: [],
    approveDialog: false,
    isApproveDisabled: false,
    approveDialogWidth: "500px",
    approvingPercent: 0,
    saveDialog: false,
    saveDialogWidth: "500px",
    savingPercent: 0,
    approving: false,
    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",
    dateEnd: null,
    endDate: null,
    menuEndDate: false,
    QuantityToReceive: 0,
    QuantityReceived: 0,
    // ขนาดจอ
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },

    datasetExcel: [],
    ExcelFile: "",
    headerExcel: [
      { label: "No", field: "no", width: "60px" },
      { label: "เลขที่บิล", field: "id", width: "100px" },
      { label: "วันเวลา", field: "createdDatetime", width: "155px" },
      { label: "ร้านค้า", field: "shopName" },
      { label: "ออกจากคลัง", field: "fromWarehouseName", width: "250px" },
      { label: "เข้าที่คลัง", field: "toWarehouseName", width: "250px" },
      { label: "รายการ", field: "transactionName", width: "130px" },
      { label: "เลขอ้างอิง", field: "referenceNumber", width: "130px" },
      { label: "ชื่อ Lot", field: "lotName", width: "130px" },
      { label: "สถานะ", field: "status", width: "50px" },
      { label: "ผู้สร้าง", field: "createdBy", width: "130px" },
      { label: "ผู้อนุมัติ", field: "approvedBy", width: "130px" },
      { label: "อนุมัติเวลา", field: "approvedDatetime", width: "130px" },
      { label: "หมายเหตุ", field: "remark", width: "200px" },
      { label: "ชื่อสินค้า", field: "productname", width: "130px" },
      { label: "SKU", field: "sku", width: "130px" },
      { label: "Barcode", field: "barcode", width: "130px" },
      { label: "RackNo", field: "Rack", width: "130px" },
      { label: "คุณลักษณะ1", field: "prop1Description", width: "130px" },
      { label: "คุณลักษณะ1", field: "prop2Description", width: "130px" },
      { label: "รหัสสินค้า", field: "productCode", width: "130px" },
      { label: "style Code", field: "styleCode", width: "130px" },
      { label: "colour Code", field: "colourCode", width: "130px" },
      { label: "brand", field: "brand", width: "130px" },
      { label: "จำนวนที่จะทำรายการ", field: "quantityRequest", width: "130px" },
      { label: "จำนวนที่ทำรายการจริง", field: "quantity", width: "130px" },
      { label: "วันที่ผลิต", field: "mfgDate", width: "130px" },
      { label: "วันหมดอายุ", field: "expiryDate", width: "130px" },
      { label: "หมายเหตุสินค้า", field: "itemRemark", width: "130px" },
    ],
    dateSelected: null,
    menu2: false,
    addStockExcelPopup: {
      MaxWidth: "50%",
      MaxHeight: 90,
      dialog: false,
      header: [],
      headerIn: [
        { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },

        {
          text: "ราคาทุน",
          align: "center",
          sortable: false,
          value: "costPrice",
          width: "100px",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "100px",
        },
        {
          text: "วันที่ผลิต",
          align: "center",
          sortable: false,
          value: "mfgDate",
          width: "100px",
        },
        {
          text: "วันหมดอายุ",
          align: "center",
          sortable: false,
          value: "expiryDate",
          width: "100px",
        },
        {
          text: "หมายเหตุ",
          align: "center",
          sortable: false,
          value: "remark",
          width: "100px",
        },
      ],
      header_null: [
        { text: "แถว", align: "center", sortable: false, value: "row", width: "60px" },
        { text: "sku", align: "center", sortable: false, value: "sku", width: "20%" },
        {
          text: "barcode",
          align: "center",
          sortable: false,
          value: "barcode",
          width: "20%",
        },
        {
          align: "center",
          text: "ราคาขาย",
          value: "unitPrice",
          sortable: false,
          width: "110px",
        },
        {
          text: "ราคาทุน",
          align: "center",
          sortable: false,
          value: "costPrice",
          width: "100px",
        },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantity",
          width: "100px",
        },
      ],
      count: 0,
      dataset: [],
      dataset_null: [],
      dataset_failed: [],
      loading: false,
      status: false,
      count_null: 0,
      count_successes: 0,
      count_excel: 0,
      count_failed: 0,
    },
    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 15,
    offsets: 0,
    IsMarkLot: "1",
    // ตัวแปลที่ใช้ในหน้านี้
    isApproved: false,
    ckeckWarehouse: false,
    shopList: [{ id: 0, name: "--เลือกร้านค้า--" }],
    shop: { id: 0, name: "--เลือกร้านค้า--" },
    transactionList: [
      { id: 0, name: "--เลือกประเภท--" },
      { id: 1, name: "นำเข้าสินค้า" },
      { id: 2, name: "นำออกสินค้า" },
      { id: 3, name: "สินค้าตีกลับ" },
      { id: 4, name: "ถ่ายโอนสินค้า" },
    ],
    transaction: { id: 0, name: "--เลือกประเภท--" },
    warehouseList: [{ id: 0, name: "--เลือกคลัง--", isPackhaiWarehouse: false }],
    shopWarehouseList: [{ id: 0, name: "--เลือกคลัง--", isPackhaiWarehouse: false }],
    warehouse_from_selected: { id: 0, name: "--เลือกคลัง--", isPackhaiWarehouse: false },
    warehouse_to_selected: { id: 0, name: "--เลือกคลัง--", isPackhaiWarehouse: false },
    IsSendBack: false,
    IsSendBackTemp: false,
    IsSendBackdisable: false,
    IsMessage: null,

    all_location_selected: null,
    allRackNoInput: null,

    // master
    importExportMasterIdOriginal: 0,
    importExportMaster: {
      id: 0,
      shopId: 0,
      branchId: parseInt(localStorage.getItem('Branch_BranchID')),
      fromWarehouseId: null,
      toWarehouseId: null,
      staffBranchId: parseInt(localStorage.getItem("Branch_StaffID")),
      staffShopId: null,
      transactionType: 0,
      remark: null,
      referenceNumber: null,
      referenceInfo: null,
      isUpdateToMarketplace: "true",
      lotName: null,
      approvedDatetime: null,
      approvedStaffBranchId: 0,
      approvedStaffBranchName: null,
      approvedStaffShopId: 0,
      approvedStaffShopName: null,
      createdDatetime: null,
      shopName: null,
      staffBranchName: null,
      staffShopName: null,
      fromWarehouseName: null,
      toWarehouseName: null,
      transactionName: null,
    },
    importExportDetail: [],
    importExportPhoto: [],

    // popup
    popupsearch: null,
    searchStockModelPopup: null,
    addStockPopup: {
      MaxWidth: "50%",
      MaxHeight: 90,
      dialog: false,
      header: [],
      headerIn: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },
        { text: "ลักษณะ", align: "center", sortable: false, value: "prop1" },
        { text: "รหัสสินค้า", align: "center", sortable: false, value: "productCode" },
        { text: "SKU", align: "center", sortable: false, value: "sku" },
        { text: "Barcode", align: "center", sortable: false, value: "barcode" },
      ],
      headerOut: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },

        { text: "SKU", align: "center", sortable: false, value: "sku" },
        {
          text: "วันที่นำเข้า",
          align: "center",
          sortable: false,
          value: "createdDatetime",
          width: "120px",
        },
        {
          text: "คงเหลือ",
          align: "center",
          sortable: false,
          value: "quantityLeft",
          width: "100px",
        },
        {
          text: "ติดจอง",
          align: "center",
          sortable: false,
          value: "reservedQuantity",
          width: "100px",
        },
        {
          text: "นำออกได้อีก",
          align: "center",
          sortable: false,
          value: "quantityAvailable",
          width: "100px",
        },
        {
          text: "ราคาทุน",
          align: "center",
          sortable: false,
          value: "costPrice",
          width: "100px",
        },
        {
          text: "วันที่ผลิต",
          align: "center",
          sortable: false,
          value: "manufactureDateNow",
          width: "120px",
        },
        {
          text: "วันที่หมดอายุ",
          align: "center",
          sortable: false,
          value: "expirationDateNow",
          width: "130px",
        },
        {
          text: "RackNo",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "100px",
        },
        {
          text: "หมายเหตุ",
          align: "center",
          sortable: false,
          value: "remark",
          width: "100px",
        },
      ],
      headerOutNoLot: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },
        { text: "SKU", align: "center", sortable: false, value: "sku" },
        {
          text: "จำนวน",
          align: "center",
          sortable: false,
          value: "quantityLeft",
          width: "120px",
        },
        {
          text: "รอแพ็ค",
          align: "center",
          sortable: false,
          value: "reservedQuantity",
          width: "100px",
        },
        {
          text: "คงเหลือ",
          align: "center",
          sortable: false,
          value: "quantityAvailable",
          width: "100px",
        },
        {
          text: "RackNo",
          align: "center",
          sortable: false,
          value: "rackNo",
          width: "100px",
        },
      ],
      dataset: [],
      selectProductList: [],
      productIdList: [],
    },

    // ค้นหาด้วย Barcode
    Bsearch: {
      isSearch: false,
      search: "",
      typeList: [
        { id: 0, name: "รวมรายการ" },
        { id: 1, name: "แยกรายการ" },
      ],
      type: { id: 0, name: "รวมรายการ" },
    },

    printbarcodeitem: {
      dialog: false,
      item: null,
    },
    BranchInfo:{},
    alertRackInput: false,
    IsImportValid: false,
    IsExportValid: false,
    IsReturnValid: false,
    IsTransferValid: false,
    IsAdjustValid: false,
    // รายการสินค้า detail
    headerProduct: [],
    datasetProduct: [],
    datasetProductTemp: [],
    headerProductIn: [
      { text: "ลบ", align: "center", sortable: false, value: "del", width: "60px" },
      {
        text: "ปริ้น",
        align: "center",
        sortable: false,
        value: "printbarcode",
        width: "60px",
      },
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "รูป",
        align: "center",
        sortable: false,
        value: "photoLink",
        width: "70px",
      },
      { text: "สินค้า", align: "left", sortable: false, value: "name" },

      { text: "SKU", align: "left", sortable: false, value: "sku", width: "150px" },
      {
        text: "Barcode",
        align: "left",
        sortable: false,
        value: "barcode",
        width: "150px",
      },
      {
        text: "จำนวนที่จะนำเข้า",
        align: "center",
        sortable: false,
        value: "quantityRequest",
        width: "90px",
      },
      {
        text: "จำนวนนำเข้าจริง",
        align: "center",
        sortable: false,
        value: "quantity",
        width: "90px",
      },

      {
        text: "วันที่ผลิต",
        align: "center",
        sortable: false,
        value: "manufactureDateNow",
        width: "175px",
      },
      {
        text: "วันที่หมดอายุ",
        align: "center",
        sortable: false,
        value: "expirationDateNow",
        width: "175px",
      },
      {
        text: "RackNo",
        align: "center",
        sortable: false,
        value: "rackNo",
        width: "150px",
      },
      {
        text: "หมายเหตุ",
        align: "center",
        sortable: false,
        value: "remark",
        width: "150px",
      },
    ],
    headerProductOut: [
      { text: "ลบ", align: "center", sortable: false, value: "del", width: "60px" },
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "รูป",
        align: "center",
        sortable: false,
        value: "photoLink",
        width: "70px",
      },
      { text: "สินค้า", align: "left", sortable: false, value: "name" },
      {
        text: "วันที่นำเข้า",
        align: "center",
        sortable: false,
        value: "firstImportDate",
        width: "110px",
      },
      {
        text: "คงเหลือก่อนนำออก",
        align: "center",
        sortable: false,
        value: "quantityBeforeExport",
        width: "110px",
      },
      {
        text: "ที่จะนำออก",
        align: "center",
        sortable: false,
        value: "quantityRequest",
        width: "90px",
      },
      {
        text: "นำออกจริง",
        align: "center",
        sortable: false,
        value: "quantity",
        width: "90px",
      },
      {
        text: "หมายเหตุ",
        align: "center",
        sortable: false,
        value: "remark",
        width: "150px",
      },
      {
        text: "RackNo",
        align: "center",
        sortable: false,
        value: "rackNo",
        width: "90px",
      },

      {
        text: "วันที่ผลิต",
        align: "center",
        sortable: false,
        value: "manufactureDateNow",
        width: "175px",
      },
      {
        text: "วันที่หมดอายุ",
        align: "center",
        sortable: false,
        value: "expirationDateNow",
        width: "175px",
      },
    ],
    headerProductTransfer: [
      { text: "ลบ", align: "center", sortable: false, value: "del", width: "60px" },
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "รูป",
        align: "center",
        sortable: false,
        value: "photoLink",
        width: "70px",
      },
      { text: "สินค้า", align: "left", sortable: false, value: "name" },
      {
        text: "วันที่นำเข้า",
        align: "center",
        sortable: false,
        value: "firstImportDate",
        width: "110px",
      },
      {
        text: "คงเหลือก่อนโอนย้าย",
        align: "center",
        sortable: false,
        value: "quantityBeforeExport",
        width: "110px",
      },
      {
        text: "ที่จะโอนย้าย",
        align: "center",
        sortable: false,
        value: "quantityRequest",
        width: "90px",
      },
      {
        text: "โอนย้ายจริง",
        align: "center",
        sortable: false,
        value: "quantity",
        width: "90px",
      },
      {
        text: "หมายเหตุ",
        align: "center",
        sortable: false,
        value: "remark",
        width: "150px",
      },
      {
        text: "RackNo",
        align: "center",
        sortable: false,
        value: "rackNo",
        width: "90px",
      },

      {
        text: "วันที่ผลิต",
        align: "center",
        sortable: false,
        value: "manufactureDateNow",
        width: "175px",
      },
      {
        text: "วันที่หมดอายุ",
        align: "center",
        sortable: false,
        value: "expirationDateNow",
        width: "175px",
      },
    ],
    headerProductSendback: [
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "ปริ้น",
        align: "center",
        sortable: false,
        value: "printbarcode",
        width: "60px",
      },
      {
        align: "center",
        text: "รูป",
        value: "photoLink",
        sortable: false,
        width: "100px",
      },
      { align: "left", text: "สินค้า", value: "name", sortable: false },
      {
        align: "center",
        text: "ที่ต้องรับคืน",
        value: "quantityRequest",
        sortable: false,
        width: "90px",
      },
      {
        align: "center",
        text: "รับคืนจริง",
        value: "quantity",
        sortable: false,
        width: "90px",
      },

      {
        align: "center",
        text: "RackNo",
        value: "rackNo",
        sortable: false,
        width: "90px",
      },
      {
        align: "center",
        text: "ราคาทุน",
        value: "costPrice",
        sortable: false,
        width: "90px",
      },
      {
        align: "center",
        text: "วันที่ผลิต",
        value: "manufactureDateNow",
        sortable: false,
        width: "175px",
      },
      {
        align: "center",
        text: "วันที่หมดอายุ",
        value: "expirationDateNow",
        sortable: false,
        width: "175px",
      },
      {
        text: "คลัง",
        align: "center",
        sortable: false,
        value: "warehouseName",
        width: "150px",
      },
      {
        text: "หมายเหตุ",
        align: "center",
        sortable: false,
        value: "remark",
        width: "150px",
      },

    ],
    audio_buzzer:null,
    chooseRackNoDialog: false,
    checkRackInputCounter: 0,
    isBtnSaveAllRackPressed: false,
    row_selected: [],
    actionMenuList: [
      { name: "ลบจำนวน", btn: "btnDeleteAllQuantity" },
      { name: "ระบุ RackNo", btn: "btnChooseAllRack" },

    ],
    // เอกสาร
    headerDoc: [
      { text: "ลบ", align: "center", sortable: false, value: "del", width: "60px" },
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      {
        text: "เอกสาร",
        align: "center",
        sortable: false,
        value: "fileUrl",
        width: "100px",
      },
      { text: "", align: "left", sortable: false, value: "select" },
      { text: "", align: "center", sortable: false, value: "btn", width: "150px" },
    ],
    savechanges: false,
  }),

  computed: {
    present_wait() {
      let tempdata = this.addStockExcelPopup.count_successes;
      if (this.addStockExcelPopup.Total_Round == 0) {
        return 0;
      } else {
        let num =
          this.addStockExcelPopup.count_successes + this.addStockExcelPopup.count_failed;
        let num100 = num * 100;
        return parseInt(num100 / this.addStockExcelPopup.Total_Round);
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {

    this.BranchInfo = JSON.parse(localStorage.getItem("BranchInfo"));
    this.location_list = await this.getLocation();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.window.width < 960) {
      this.addStockPopup.MaxWidth = "100%";
    } else {
      this.addStockPopup.MaxWidth = "70%";
    }
    if (this.size == "xs" || this.size == "sm") {
      this.limits = 25;
      this.addStockPopup.MaxHeight = (90 * this.window.height) / 100;
    } else {
      this.addStockPopup.MaxHeight =
        (this.addStockPopup.MaxHeight * this.window.height) / 100;
      // cal limits

      this.limits = parseInt((this.addStockPopup.MaxHeight - 211) / 90) + 1;
    }

    this.importExportMasterIdOriginal = parseInt(atob(this.$route.query.id));
    this.importExportMaster.id = parseInt(atob(this.$route.query.id));

    if (this.importExportMaster.id != 0) {
      await this.GetImportExportMaster(this.importExportMaster.id);
      this.importExportMasterIdOriginal = this.importExportMaster.id;
      this.navigete =
        this.importExportMaster.transactionName +
        "เลขอ้างอิง " +
        this.GenIE(this.importExportMaster.id);
      document.title =
        this.GenIE(this.importExportMaster.id) +
        " " +
        this.importExportMaster.transactionName;
    } else {
      await this.getShopList();
      await this.getWarehouseList();
      this.navigete = "เพิ่มรายการ";
      document.title = "เพิ่มรายการ";
    }
    this.savechanges = false;
    this.page_loading = false;

    this.audio_buzzer = new Audio(require("../deliveryNote/buzzer.wav"));


    this.location_name_item = this.location_list.map(function (item) { return item.locationShortName; });


  },
  watch: {
  },
  methods: {
    async getShopList() {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-list",
        {
          shopType: 1,
          branchId: this.BranchID,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        if (response.data.result.length > 0) {
          for (var s in response.data.result) {
            this.shopList.push({
              id: response.data.result[s].shop.id,
              name: response.data.result[s].shop.name,
            });
          }
        }
      }
    },
    async getWarehouseList() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-warehouse-list",
        {
          branchId: this.BranchID,
          importExportMasterId: this.importExportMaster.id,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.warehouseList = [
          { id: 0, name: "--เลือกคลัง--", isPackhaiWarehouse: false },
        ];
        for (var i in response.data) {
          this.warehouseList.push({
            id: response.data[i].id,
            name: response.data[i].name,
            isPackhaiWarehouse: response.data[i].isPackhaiWarehouse,
          });
        }
      }
    },
    async getShopWarehouseList(shopid) {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-warehouse-list",
        {
          shopId: shopid,
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.shopWarehouseList = [
          { id: 0, name: "--เลือกคลัง--", isPackhaiWarehouse: false },
        ];
        for (var i in response.data) {
          this.shopWarehouseList.push({
            id: response.data[i].id,
            name:
              response.data[i].name +
              (response.data[i].isPackhaiWarehouse ? "" : " (ร้านค้า)"),
            isPackhaiWarehouse: response.data[i].isPackhaiWarehouse,
          });
        }
      }
    },
    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),
    async GetImportExportMaster(importExportMasterID) {
      let dataResponse = await axios.get(
        stockService_dotnet +
        "Stock/get-import-export?ID=" +
        importExportMasterID +
        "&isAscending=true",
        { headers: this.header_token }
      );
      this.importExportMaster = dataResponse.data.importExportMaster;
      this.importExportDetail = [];
      this.importExportMaster.isUpdateToMarketplace = this.importExportMaster.isUpdateToMarketplace.toString();
      console.log(dataResponse.data);
      
      if (
        this.importExportMaster.approvedDatetime != null  
      ) {
        var i = 1;
        // ถ้า approved ต้องเรียงธรรมดา
        for (
          let index = 0;
          index < dataResponse.data.importExportDetail.length;
          index++
        ) {
          dataResponse.data.importExportDetail[index].no = i;
          i++;
          this.importExportDetail.push(dataResponse.data.importExportDetail[index]);
        }
      } else {
        // ถ้ายัง ไม่ approved ต้องเรียงย้อนกลับ
        i = dataResponse.data.importExportDetail.length;
        for (
          let index = dataResponse.data.importExportDetail.length - 1;
          index >= 0;
          index--
        ) {
          dataResponse.data.importExportDetail[index].no = i;
          dataResponse.data.importExportDetail[index].isUpdated = false;
          var tempDetail = dataResponse.data.importExportDetail[index];
        
          if (this.location_list.length > 0) {
            var findByText = false;
            
            if (tempDetail.locationID != null) {
              var locationfound = this.location_list.find(item => item.locationID == tempDetail.locationID);
              if (locationfound != null) {
                tempDetail.location_selected = locationfound;
              } else {
                findByText = true;
              }
            } else {
              findByText = true;
            }

            if (findByText && tempDetail.rackNo) {
              locationfound = this.location_list.find(item => item.locationShortName == tempDetail.rackNo);

              if (locationfound != null) {
                tempDetail.location_selected = locationfound;
              } else {
                tempDetail.locationID = 0;
                tempDetail.location_selected = {
                  locationID: 0,
                  locationName: '',
                  locationShortName: tempDetail.rackNo
                }
              }
            }
          }
          i--;
          this.importExportDetail.push(dataResponse.data.importExportDetail[index]);
        }
      }

      for (var imdetail of this.importExportDetail) {
        //imdetail.manufactureDateNow = imdetail.mfgDate == null ? null : imdetail.mfgDate;
        imdetail.manufactureDateStart =
          imdetail.mfgDate == null
            ? null
            : this.parseDate(this.formatDate_thai(imdetail.mfgDate));
        imdetail.manufactureDateNow = this.formatDate(imdetail.manufactureDateStart);
        imdetail.manufactureDateMenu = false;
        //imdetail.expirationDateNow =imdetail.expiryDate == null ? null : imdetail.expiryDate;
        imdetail.expirationDateStart =
          imdetail.expiryDate == null
            ? null
            : this.parseDate(this.formatDate_thai(imdetail.expiryDate));
        imdetail.expirationDateNow = this.formatDate(imdetail.expirationDateStart);
        imdetail.expirationDateMenu = false;

        if (
          this.importExportMaster.transactionType == 2 ||
          this.importExportMaster.transactionType == 4
        ) {
          if (imdetail.stockShopItemId != null) {
            this.IsMarkLot = "2";
          }
        }
      }

      this.ckeckWarehouse = true;
      this.isApproved = this.importExportMaster.approvedDatetime != null ? true : false;

      this.Check_Headers_Product(this.importExportMaster.transactionType);
      this.Check_Headers_Select_Product(this.importExportMaster.transactionType);
      if (this.importExportMaster.approvedDatetime != null) {
        this.headerProduct.splice(0, 0, {
          text: "สำเร็จ",
          align: "center",
          sortable: false,
          value: "success",
          width: "60px",
        });
      }

      if (this.importExportMaster.transactionType == 1) {
        this.IsImportValid = true;
        if (this.importExportMaster.approvedDatetime != null) {
          this.headerProduct.splice(1, 1);
        }
        if (this.importExportMaster.toIsPackhaiWarehouse) {
          this.IsApproveButtonVisible = true;
        }
      } else if (this.importExportMaster.transactionType == 2) {
        this.IsExportValid = true;
        if (this.importExportMaster.approvedDatetime != null) {
          this.headerProduct.splice(1, 1);
          this.headerProduct.splice(5, 1);
        }
        if (this.importExportMaster.fromIsPackhaiWarehouse) {
          this.IsApproveButtonVisible = true;
        }
      } else if (this.importExportMaster.transactionType == 3) {
        this.IsSendBackdisable = true;
        this.IsReturnValid = true;
        if (this.importExportMaster.toIsPackhaiWarehouse) {
          this.IsApproveButtonVisible = true;
        }
      } else if (this.importExportMaster.transactionType == 4) {
        this.IsTransferValid = true;
        if (this.importExportMaster.toIsPackhaiWarehouse) {
          this.IsApproveButtonVisible = true;
        }
        if (this.importExportMaster.approvedDatetime != null) {
          this.headerProduct.splice(1, 1);
          this.headerProduct.splice(5, 1);
        }
      } else if (this.importExportMaster.transactionType == 5) {
        this.IsAdjustValid = true;
        if (this.importExportMaster.approvedDatetime != null) {
          this.headerProduct.splice(1, 1);
        }
        if (this.importExportMaster.toIsPackhaiWarehouse) {
          this.IsApproveButtonVisible = true;
        } else if (this.importExportMaster.approvedDatetime == null) {
          this.headerProduct.splice(8, 1);
        }
      }

      if (!this.staffPermission.ApproveStock) {
        this.IsApproveButtonVisible = false;
      }

      this.shop = {};
      this.shop.id = this.importExportMaster.shopId;
      //เอกสาร
      this.importExportPhoto = dataResponse.data.importExportPhoto;

      for (var p of this.importExportPhoto) {
        var filename = null;
        if (p.fileUrl != null) {
          var file_name = p.fileUrl.split("/");
          filename = file_name[4];
        }
        p.fileUpdate = {
          file: [],
          file_url: null,
          file_name: filename,
          file_size: 0,
          from_data: {},
          from_path: "",
        };
      }
      this.SumQuantity();
      await this.PrepareExcel();
    },
    UpdateStockMaster1(name, v) {
      if (v == "") {
        v = null;
      }
      var value = this.Trim_value(v);
      this.UpdateStockMaster2(name, value);
      return value;
    },
    async UpdateStockMaster2(name, v) {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      if ("referenceNumber" == name) {
        this.importExportMaster.referenceNumber = v;
      }
      if ("remark" == name) {
        this.importExportMaster.remark = v;
      }
      if ("lotName" == name) {
        this.importExportMaster.lotName = v;
      }
      if ("isUpdateToMarketplace" == name) {
        this.importExportMaster.isUpdateToMarketplace = v;
      }
      var IsSaveSuccess = false;
      if (this.importExportMaster.id == 0) {
        this.loading = true;
      }
      while (!IsSaveSuccess) {
        try {
          this.savechanges = true;
          if(this.importExportMaster.approvedDatetime!=null){
            let update = await axios.post(
              stockService_dotnet + "Stock/update-import-export-master-after-approved",
              this.importExportMaster,
              { headers: this.header_token }
            );
            if (update.status == 200 && update.data.status == "success") {
              IsSaveSuccess = true;
            }

          }
          else
          {
            let update = await axios.post(
              stockService_dotnet + "Stock/update-import-export-master-v2",
              this.importExportMaster,
              { headers: this.header_token }
            );
            if (update.status == 200 && update.data.status == "success") {
              IsSaveSuccess = true;
              if (this.importExportMaster.id == 0) {
                this.importExportMaster.id = update.data.importExportMasterID;
                let dataResponse = await axios.get(
                  stockService_dotnet +
                  "Stock/get-import-export?ID=" +
                  this.importExportMaster.id +
                  "&isAscending=true",
                  { headers: this.header_token }
                );

                this.importExportMaster = dataResponse.data.importExportMaster;
                this.importExportMaster.isUpdateToMarketplace = this.importExportMaster.isUpdateToMarketplace.toString();
              }
            }
          }
        } catch (e) {
          // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
          //this.SendError('warehouse','stock','UpdateStockMaster2',localStorage.getItem('Branch_BranchID'),null,null,e,null)
        }
      }
      this.loading = false;
    },
    UpdateStockDetail1(item, name, v) {
      
      
      if (v == "") {
        v = null;
      }

      if (typeof v === 'object') {
        //
        value = v;
      } else {
        var value = this.Trim_value(v);
      }



      this.UpdateStockDetail2(item, name, value);
     

      return value;
    },
    async UpdateStockDetail2(item, name, v) {
      item.isUpdated = true;
      var quantityTemp = 0;
      if ("quantity" == name) {
        quantityTemp = item.quantity;
        if (v == null || v == '')
          v = "0";
        item.quantity = parseInt(v);
      } else if ("quantitySendback" == name) {
        quantityTemp = item.quantitySendback == null ? 0 : item.quantitySendback;
        item.quantitySendback = v;
      } else if ("costPrice" == name) {
        item.costPrice = v;
      } else if ("rackNo" == name) {

        if (this.location_list.length > 0) {
          if (typeof v === 'object') {
            //
            if (item.location_selected == null) {
              item.rackNo = null;
              item.locationID = null;
            } else {
              item.rackNo = item.location_selected.locationShortName;
              item.locationID = item.location_selected.locationID;
            }

          }
          else {
            item.rackNo = v;
            item.locationID = 0;
          }
        } else {
          item.rackNo = v;
          if (v == '') {
            item.rackNo = null;
          }
          item.locationID == null;
        }
      } else if ("remark" == name) {
        item.remark = v;
      } else if ("barcode" == name) {
        item.barcode = v;
        let update = await axios.post(
          stockService_dotnet + "Stock/update-product-barcode",
          {
            productMasterItemID: item.productMasterItemId,
            barcode: item.barcode,
          },
          { headers: this.header_token }
        );
        return;
      } else if ("expirationDateStart" == name) {
        if (v != null) {
          item.expirationDateNow = this.formatDate(item.expirationDateStart);
        } else {
          item.expirationDateNow = null;
        }
        item.expiryDate = v;
      } else if ("manufactureDateStart" == name) {
        if (v != null) {
          item.manufactureDateNow = this.formatDate(item.manufactureDateStart);
        } else {
          item.manufactureDateNow = null;
        }
        item.mfgDate = v;
      }

      if (
        "quantity" == name &&
        item.quantity != null &&
        v > item.quantityLeft &&
        this.transaction.id == 2
      ) {
        setTimeout(function () {
          item.quantity = quantityTemp;
        }, 500);
        return;
      } else if (
        "quantitySendback" == name &&
        item.quantitySendback != null &&
        v > item.quantity &&
        this.transaction.id == 3
      ) {
        setTimeout(function () {
          item.quantitySendback = quantityTemp;
        }, 500);
        return;
      }
      this.SumQuantity();
      this.savechanges = true;
      // var IsSaveSuccess = false;
      // while (!IsSaveSuccess) {
      //   try {

      //     let update = await axios.post(
      //       stockService_dotnet + "Stock/update-import-export-detail-v2",

      //       {
      //         id: item.id,
      //         importExportMasterId: item.importExportMasterId,
      //         productMasterItemId: item.productMasterItemId,
      //         stockShopId: item.stockShopId,
      //         stockShopItemId: item.stockShopItemId,
      //         quantityRequest: item.quantityRequest,
      //         quantity: item.quantity,
      //         costPrice: item.costPrice,
      //         rackNo: item.rackNo,
      //         mfgDate: item.mfgDate,
      //         expiryDate: item.expiryDate,
      //         remark: item.remark,
      //       },

      //       { headers: this.header_token }
      //     );

      //     if (update.status == 200) {
      //       if (update.data.status == "success") {
      //         // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
      //         //this.SendError('warehouse','stock',stockService_dotnet+'Stock/update-stock-importexport',localStorage.getItem('Branch_BranchID'),null,null,update.data.description,update.data.message)
      //         // Swal.fire({
      //         //   icon: "warning",
      //         //   title: "ไม่สามารถทำรายการได้",
      //         //   text: update.data.message,
      //         //   showConfirmButton: true,
      //         //   confirmButtonText: "เช็คอีกครั้ง",
      //         // });
      //         IsSaveSuccess = true;
      //       }
      //     } else {
      //       // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
      //       //this.SendError('warehouse','stock',stockService_dotnet+'Stock/update-stock-importexport',localStorage.getItem('Branch_BranchID'),null,null,null,null)
      //     }
      //   } catch (e) {
      //     // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
      //     //this.SendError('warehouse','stock','UpdateStockDetail2',localStorage.getItem('Branch_BranchID'),null,null,e,null)
      //   }
      // }

      if (item.quantity == null) {
        setTimeout(function () {
          item.quantity = 0;
        }, 500);
        return;
      } else if (item.quantitySendback == null) {
        setTimeout(function () {
          item.quantitySendback = 0;
        }, 500);
        return;
      }
    },
    DeleteProduct(item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        title: "ต้องการลบใช่หรือไม่",
        text:
          item.name +
          " " +
          (item.prop1Description != null && item.prop1Description != ""
            ? item.prop1Description
            : "") +
          (item.prop2Description != null && item.prop2Description != ""
            ? " , " + item.prop2Description
            : ""),
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          // ลบ details
          if (item.id > 0) {
            try {
              this.savechanges = true;
              let Remove = await axios.post(
                stockService_dotnet +
                "Stock/delete-import-export-detail-v2?id=" +
                item.id,
                {},
                { headers: this.header_token }
              );
              if (Remove.status == 200 && Remove.data.status == "success") {
                this.importExportDetail.splice(this.importExportDetail.indexOf(item), 1);
              } else {
                // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
                Swal.fire({
                  icon: "error",
                  title: "ไม่สามารถทำรายการได้",
                  text: Remove.data.message[this.language],
                });
              }
            } catch (e) {
              // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
              Swal.fire({
                icon: "error",
                title: "ไม่สามารถทำรายการได้",
                text: "กรุณาติดต่อผู้ดูแลระบบ",
              });
            }
          } else {
            this.importExportDetail.splice(this.importExportDetail.indexOf(item), 1);
          }
          var counter = this.importExportDetail.length;
          var i = 1;
          for (var dataitem of this.importExportDetail) {
            if (
              this.importExportMaster.transactionType == 1 ||
              this.importExportMaster.transactionType == 2
            ) {
              dataitem.no = counter;
              counter--;
            } else {
              dataitem.no = i;
              i++;
            }
          }
          this.SumQuantity();
        }
      });
    },
    btnDeleteAllQuantity() {

      for (var row of this.row_selected) {
        row.quantity = 0;
        row.isUpdated = true;
        this.savechanges = true;
      }
      this.SumQuantity();
    },
    btnChooseAllRack() {
      this.all_location_selected = null;
      this.allRackNoInput = null;
      this.chooseRackNoDialog = true;
      this.checkRackInputCounter = 0;
      this.isBtnSaveAllRackPressed = false;
      this.alertRackInput = false;
    },
    FilterOnlyNew(InputData) {
      var detailTemp = [];
      for (var i of InputData) {
        if (i.importExportDetailId == null || i.importExportDetailId == 0) {
          detailTemp.push(i);
        }
      }
      return detailTemp;
    },

    SetImportexportMasterApi() {
      var product = this.importExportMaster;
      var data = {
        id: parseInt(product.id),
        branchId: product.branchId,
        shopId: this.transaction.id == 3 ? this.importExportMaster.shopId : this.shop.id,
        staffBranchId: product.staffBranchId,
        staffShopId: null,
        fromWarehouseId:
          this.warehouse_from_selected.id == 0 ? null : this.warehouse_from_selected.id,
        toWarehouseId:
          this.transaction.id == 3
            ? this.warehouseList[1].id
            : this.warehouse_to_selected.id == 0
              ? null
              : this.warehouse_to_selected.id,
        transactionType: this.transaction.id == 0 ? null : this.transaction.id,
        remark: product.remark,
        referenceNumber: product.referenceNumber,
        referenceInfo: this.SetReferenceInfo(product.referenceInfo),
        lotName: this.importExportMaster.lotName,
      };
      return data;
    }, 

    async Load_Data_1_loadPage(check) {
      await this.Load_Data_1();
    },

    async Load_Data_1() {
      await axios
        .all([
          axios.post(
            shopService_dotnet + "Shop/get-shop-list",
            {
              shopType: 1,
              branchId: this.BranchID,
            },
            { headers: this.header_token }
          ),

          axios.post(
            branchService_dotnet + "Branch/get-warehouse-list",
            {
              branchId: this.BranchID,
              importExportMasterId: this.importExportMaster.id,
            },
            { headers: this.header_token }
          ),

          axios.get(
            stockService_dotnet +
            "Stock/get-import-export?ID=" +
            this.importExportMaster.id +
            "&isAscending=true",
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread((shopList, warehouseList, dataResponse) => {
            if (warehouseList.status == 200 && dataResponse.status == 200) {
              shopList = shopList.data.result;
              warehouseList = warehouseList.data;

              // แกไข

              // เอกสาร
              // this.datasetDoc = [];
              // for (var p of importexportPhotoList) {
              //   var filename = null;
              //   if (p.fileUrl != null) {
              //     var file_name = p.fileUrl.split("/");
              //     filename = file_name[4];
              //   }

              //   this.datasetDoc.push({
              //     id: p.id,
              //     importExportMasterId: p.importExportMasterId,
              //     fileUrl: p.fileUrl,
              //     fileUpdate: {
              //       file: [],
              //       file_url: null,
              //       file_name: filename,
              //       file_size: 0,
              //       from_data: {},
              //       from_path: "",
              //     },
              //   });
              // }

              this.Check_Headers_Product(this.importExportMaster.transactionType);
              this.Check_Headers_Select_Product(this.importExportMaster.transactionType);
              if (this.isApproved) {
                this.headerProduct.splice(0, 0, {
                  text: "สำเร็จ",
                  align: "center",
                  sortable: false,
                  value: "success",
                  width: "60px",
                });
              }

              if (this.importExportMaster.transactionType == 1) {
                if (this.isApproved) {
                  this.headerProduct.splice(1, 1);
                }
              }
              if (this.importExportMaster.transactionType == 2) {
                if (this.isApproved) {
                  this.headerProduct.splice(1, 1);
                }
              }
            } else {
              this.AlertError();
            }
          })
        );
    },
    // ข้อมูลตาราง
    async OpenPopup() {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      this.searchStockModelPopup = null;
      this.addStockPopup.selectProductList = [];
      this.addStockPopup.dataset = [];
      this.addStockPopup.productIdList = [];
      this.popupsearch = null;

      if (this.transaction.id != 1) {
        for (var i in this.datasetProduct) {
          if (!this.addStockPopup.productIdList.includes(this.datasetProduct[i].id)) {
            this.addStockPopup.productIdList.push(this.datasetProduct[i].id);
          }
        }
      }
      this.getSelectProduct(this.limits, 0, 1, this.searchStockModelPopup);
    },
    async getSelectProduct(limits, offsets, page, searchstock) {
      this.loading = true;
      let response = null;
      if (this.importExportMaster.transactionType == 1) {
        response = await axios.post(
          stockService_dotnet + "Stock/get-import-product-list",
          {
            shopId: this.importExportMaster.shopId,
            name: this.SearchBy == "ชื่อสินค้า" ? searchstock : null,
            productCode: this.SearchBy == "รหัสสินค้า" ? searchstock : null,
            barcode: this.SearchBy == "Barcode" ? searchstock : null,
            sku: this.SearchBy == "SKU" ? searchstock : null,
            warehouseId_In: this.importExportMaster.toWarehouseId,
            skip: offsets,
            take: limits,
          },
          { headers: this.header_token }
        );
      } else {
        var StockShopItemIDList = [];
        for (var i of this.importExportDetail) {
          if (i.stockShopItemId != null && i.stockShopItemId != 0) {
            StockShopItemIDList.push(i.stockShopItemId);
          }
        }
        response = await axios.post(
          stockService_dotnet + "Stock/get-export-product-list",
          {
            shopId: this.shop.id,
            name: this.SearchBy == "ชื่อสินค้า" ? searchstock : null,
            productCode: this.SearchBy == "รหัสสินค้า" ? searchstock : null,
            barcode: this.SearchBy == "Barcode" ? searchstock : null,
            sku: this.SearchBy == "SKU" ? searchstock : null,
            warehouseId_Out: this.importExportMaster.fromWarehouseId,
            skipStockShopItemIdList: StockShopItemIDList,
            IsMarkLot: parseInt(this.IsMarkLot) == 2 ? true : false,
            skip: offsets,
            take: limits,
          },
          { headers: this.header_token }
        );
      }
      if (response.status == 200) {
        this.pageLength = Math.ceil(response.data.resultCount / this.limits);
        if (this.pageLength <= 0) {
          this.pageLength = 1;
        }
        this.page = page;
        this.pageTemp = page;
        this.offsets = offsets;
        this.addStockPopup.dataset = response.data.resultData;
        this.SetUp_Product();
        this.loading = false;
        this.addStockPopup.dialog = true;
      } else {
        this.loading = false;
        this.AlertError();
      }
    },
    SetUp_Product() {
      for (var a in this.addStockPopup.dataset) {
        var selected = this.addStockPopup.selectProductList.filter(
          (x) =>
            x.stockShopItemId == this.addStockPopup.dataset[a].stockShopItemId &&
            x.stockShopId == this.addStockPopup.dataset[a].stockShopId
        );

        if (selected != null && selected.length > 0) {
          if (this.importExportMaster.transactionType == 1) {
            // ตอนนำเข้า นำออกใช้งานได้ปกติ แต่นำเข้าใช้ไม่ได้เลยต้อง if แยกส่วนเอาไว้
            // ส้วนนี้เป็นการหา index ของ addStockPopup.dataset ที่มี id ตรงกับ selected.id ด้านบน
            const index = this.addStockPopup.dataset.reduce((acc, obj, index) => {
              for (const selectObject of selected) {
                // ต้องloop เพราะselected ไม่ได้มีแค่1ตัว
                if (selectObject.id === obj.id) {
                  acc.push(index);
                  break;
                }
              }
              return acc;
            }, []);
            for (var indexs of index) {
              this.addStockPopup.dataset[indexs].selectProduct = true;
            }
          } else {
            // อันนี้นำออก
            this.addStockPopup.dataset[a].selectProduct = true;
          }
        }
      }
    },
    async SearchBarcode() {
      this.Bsearch.search = this.Bsearch.search.trim();
      if (this.Bsearch.search != "" && this.Bsearch.search != null) {
        if (this.Bsearch.type.id == 0) {
          var IsFound = false;
          for (var productItem of this.importExportDetail) {
            if (productItem.barcode == this.Bsearch.search) {
              this.UpdateStockDetail1(
                productItem,
                "quantity",
                (parseInt(productItem.quantity??0) + 1).toString()
              );

              this.Bsearch.search = "";
              this.$refs.refBsearch.focus();
              return;
            }
          }
        }

        try {
          if (this.importExportMaster.transactionType == 1) {
            this.addStockPopup.selectProductList = [];
            this.loading = true;
            let response = await axios.post(
              stockService_dotnet + "Stock/get-import-product-list",
              {
                shopId: this.importExportMaster.shopId,
                barcode: this.Bsearch.search,
                isExactMatch:true,
                warehouseId_In: this.warehouse_to_selected.id,
              },
              { headers: this.header_token }
            );
            this.loading = false;
            if (response.status == 200) {
              if (response.data.status && response.data.message == null) {
                this.Bsearch.search = "";
                this.Bsearch.isSearch = true;
                for (var item of response.data.resultData) {
                  this.addStockPopup.selectProductList.splice(0, 0, item);
                }
 
                if (this.addStockPopup.selectProductList.length > 0) {
                  this.Submit_addStockPopup();
                }
                else{
                  this.audio_buzzer.play();
                }
                this.$refs.refBsearch.focus();
              } else {
                // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
                this.SendError(
                  "warehouse",
                  "stock",
                  stockService_dotnet + "Stock/get-import-product-list",
                  this.BranchID,
                  null,
                  null,
                  response.data.description,
                  response.data.message
                );
              }
            } else {
              // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
              this.SendError(
                "warehouse",
                "stock",
                stockService_dotnet + "Stock/get-import-product-list",
                this.BranchID,
                null,
                null,
                null,
                null
              );
            }
          } else if (
            this.importExportMaster.transactionType == 2 ||
            this.importExportMaster.transactionType == 4
          ) {
            var response = await axios.post(
              stockService_dotnet + "Stock/get-export-product-list",
              {
                shopId: this.shop.id,
                name: null,
                productCode: null,
                barcode: this.Bsearch.search,
                sku: null,
                warehouseId_Out: this.importExportMaster.fromWarehouseId,
                IsMarkLot: false,
                skip: 0,
                take: 100,
              },
              { headers: this.header_token }
            );
            if (response.status == 200) {
              this.Bsearch.search = "";
              this.Bsearch.isSearch = true;
              if (
                response.data.resultData == null ||
                response.data.resultData.length == 0
              ) {
                Swal.fire({
                  type: "info",
                  text: response.data.error_description,
                });
              } else {
                for (var item1 of response.data.resultData) {
                  this.addStockPopup.selectProductList.splice(0, 0, item1);
                }
                if (this.addStockPopup.selectProductList.length > 0) {
                  this.Submit_addStockPopup();
                }
              }
              this.$refs.refBsearch.focus();
            }
          }
        } catch (e) {
          this.loading = false;
          // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
          this.SendError(
            "warehouse",
            "stock",
            "SearchBarcode",
            this.BranchID,
            null,
            null,
            e,
            null
          );
        }
      }
    },
    async PopupsearchStock(limits, offsets) {
      if (this.searchStockModelPopup == null) {
        this.searchStockModelPopup = "";
      }
      this.popupsearch = this.searchStockModelPopup.trim();
      if (this.popupsearch.length > 0) {
        this.getSelectProduct(limits, offsets, 1, this.popupsearch);
      } else {
        this.getSelectProduct(limits, offsets, 1, null);
      }
    },
    ProductSelectCheckbox(item, index) {
      item["selectProduct"] = !item["selectProduct"];
      if (item["selectProduct"]) {
        //นำเข้า selectProductList
        this.addStockPopup.selectProductList.splice(0, 0, item);
      } else {
        //นำออก selectProductList

        var data = this.addStockPopup.selectProductList;
        for (var i in data) {
          if (
            data[i]["stockShopId"] == item["stockShopId"] &&
            data[i]["stockShopItemId"] == item["stockShopItemId"]
          ) {
            this.addStockPopup.selectProductList.splice(i, 1);
            break;
          }
        }
      }
    },
    ProductSelect(item, index) {
      this.addStockPopup.dataset[index]["selectProduct"] = !item["selectProduct"];
      if (item["selectProduct"]) {
        //นำเข้า selectProductList
        this.addStockPopup.selectProductList.splice(0, 0, item);
      } else {
        //นำออก selectProductList
        var data = this.addStockPopup.selectProductList;
        for (var i in data) {
          if (data[i]["id"] == item["id"]) {
            this.addStockPopup.selectProductList.splice(i, 1);
            break;
          }
        }
      }
    },
    async Submit_addStockPopup() {
      this.loading=true;
     
      
      try {
        this.addStockPopup.selectProductList = await this.OrderBy(
          this.addStockPopup.selectProductList
        );
        var selected_product = this.addStockPopup.selectProductList;

        // เก็บ productMasterItemId ไว้เช็ค รวมรายการ
        var AlertProductDuplicate = "";

        // Bsearch.type.id = 0   รวมรายการ
        // Bsearch.type.id = 1   แยกรายการ
        for (var i in selected_product) {
          var datasetProduct = {};
          var addDatasetProduct = false;
          if (this.importExportMaster.transactionType == 1) {
            // เช็ครายการเพิ่มสินค้า รวมรายการ
            var product = this.importExportDetail.filter(
              (x) => x.productMasterItemId == selected_product[i].id
            );
            if (product.length == 0 || this.Bsearch.type.id != 0) {
              addDatasetProduct = true;
              datasetProduct = {
                id: 0,
                productMasterItemId: selected_product[i].id,
                importExportMasterId: this.importExportMaster.id,
                stockShopId: selected_product[i].stockShopId,
                stockShopItemId: selected_product[i].stockShopItemId,
                photoLink: selected_product[i].photoLink,
                name: selected_product[i].name,
                prop1: selected_product[i].prop1,
                prop1Description: selected_product[i].prop1Description,
                prop2: selected_product[i].prop2,
                prop2Description: selected_product[i].prop2Description,
                productCode: selected_product[i].productCode,
                sku: selected_product[i].sku,
                barcode: selected_product[i].barcode,
                costPrice: null,
                quantity: 1,
                locationID:selected_product[i].locationID,
                rackNo: selected_product[i].rackNo,
                createdDatetime: null,
                manufactureDateNow: null,
                manufactureDateStart:  null,
                manufactureDateMenu: false,
                expirationDateNow: null,
                expirationDateStart: null,
                expirationDateMenu: false,
                isUpdated: false,
              };
            } else {
              // ถ้าเป็นการรวมรายการ
            
              if (product.length > 1) {
                var propDescription =
                  product[0].prop1Description != null
                    ? product[0].prop1Description +
                    (product[0].prop2Description != null
                      ? " " + product[0].prop2Description
                      : "")
                    : "";
                if (AlertProductDuplicate == "") {
                  AlertProductDuplicate = product[0].name + " " + propDescription;
                } else {
                  AlertProductDuplicate += " ," + product[0].name + " " + propDescription;
                }
              } else if (product.length == 1) {
                 
                // อัพเดทจำนวนเข้าไปตำแหน่งนั้น
                for (var k in this.importExportDetail) {
                  if (
                    this.importExportDetail[k].productMasterItemId == selected_product[i].id
                  ) {
                    
                    
                    var quantityUpdate =
                      this.importExportDetail[k].quantity == null ||
                        this.importExportDetail[k].quantity == "" ||
                        this.importExportDetail[k].quantity == 0
                        ? 1
                        : parseInt(this.importExportDetail[k].quantity) + 1;
                    this.importExportDetail[k].isUpdated = true;
                    await this.UpdateStockDetail1(
                      this.importExportDetail[k],
                      "quantity",
                      quantityUpdate.toString()
                    );
                  }
                }
              }
            }
          } else if (
            this.importExportMaster.transactionType == 2 ||
            this.importExportMaster.transactionType == 4
          ) {
            addDatasetProduct = true;
            datasetProduct = {
              id: 0,
              firstImportDate : this.formatDate_thai(selected_product[i].createdDatetime),
              productMasterItemId: selected_product[i].productMasterItemId,
              importExportMasterId: this.importExportMaster.id,
              stockShopId: selected_product[i].stockShopId,
              stockShopItemId: selected_product[i].stockShopItemId,
              photoLink: selected_product[i].photoLink,
              quantityBeforeExport: selected_product[i].quantityAvailable,
              name: selected_product[i].name,
              prop1: selected_product[i].prop1,
              prop1Description: selected_product[i].prop1Description,
              prop2: selected_product[i].prop2,
              prop2Description: selected_product[i].prop2Description,
              productCode: selected_product[i].productCode,
              sku: selected_product[i].sku,
              barcode: selected_product[i].barcode,
              costPrice: null,
              quantity: 1,
              rackNo: selected_product[i].rackNo,
              createdDatetime: null,
              manufactureDateNow: this.formatDate_thai(selected_product[i].manufactureDateStart) ,
              manufactureDateStart:selected_product[i].manufactureDateStart,
              manufactureDateMenu: false,
              expirationDateNow: this.formatDate_thai(selected_product[i].expirationDateStart),
              expirationDateStart: selected_product[i].expirationDateStart,
              expirationDateMenu: false,
              isUpdated: false,
            };
        
            
          }
          this.Bsearch.isSearch = false;
          if (addDatasetProduct) {
            if (datasetProduct.importExportMasterId == 0) {
              let UpdateMasterResponse = await axios.post(
                stockService_dotnet + "Stock/update-import-export-master-v2",
                this.importExportMaster,
                { headers: this.header_token }
              );
              if (
                UpdateMasterResponse.status == 200 &&
                UpdateMasterResponse.data.status == "success"
              ) {
                datasetProduct.importExportMasterId =
                  UpdateMasterResponse.data.importExportMasterID;
                this.importExportMaster.id = UpdateMasterResponse.data.importExportMasterID;

                let dataResponse = await axios.get(
                  stockService_dotnet +
                  "Stock/get-import-export?ID=" +
                  this.importExportMaster.id +
                  "&isAscending=true",
                  { headers: this.header_token }
                );
                this.importExportMaster = dataResponse.data.importExportMaster;

                if (this.importExportMaster.transactionType == 1) {
                  if (this.importExportMaster.toIsPackhaiWarehouse) {
                    this.IsApproveButtonVisible = true;
                  }
                } else if (this.importExportMaster.transactionType == 2) {
                  if (this.importExportMaster.fromIsPackhaiWarehouse) {
                    this.IsApproveButtonVisible = true;
                  }
                } else if (this.importExportMaster.transactionType == 3) {
                  if (this.importExportMaster.toIsPackhaiWarehouse) {
                    this.IsApproveButtonVisible = true;
                  }
                } else if (this.importExportMaster.transactionType == 4) {
                  if (this.importExportMaster.toIsPackhaiWarehouse) {
                    this.IsApproveButtonVisible = true;
                  }
                }

                this.importExportMaster.isUpdateToMarketplace = this.importExportMaster.isUpdateToMarketplace.toString();
              }
            }
            if (!this.staffPermission.ApproveStock) {
              this.IsApproveButtonVisible = false;
            }
            let UpdateDetailResponse = await axios.post(
              stockService_dotnet + "Stock/update-import-export-detail-v2",
              datasetProduct,
              { headers: this.header_token }
            );
            if (
              UpdateDetailResponse.status == 200 &&
              UpdateDetailResponse.data.status == "success"
            ) {
              datasetProduct.id = UpdateDetailResponse.data.importExportDetailID;
            }
            datasetProduct.no = this.importExportDetail.length + 1;
            datasetProduct.isUpdated = false;
            
            if (this.location_list.length > 0) {
              var findByText = false;
              if (datasetProduct.locationID != null) {
                var locationfound = this.location_list.find(item => item.locationID == datasetProduct.locationID);
                if (locationfound != null) {
                  datasetProduct.location_selected = locationfound;
                } else {
                  findByText = true;
                }
              } else {
                findByText = true;
              }

              if (findByText && datasetProduct.rackNo) {
                locationfound = this.location_list.find(item => item.locationShortName == datasetProduct.rackNo);

                if (locationfound != null) {
                  datasetProduct.location_selected = locationfound;
                } else {
                  datasetProduct.locationID = 0;
                  datasetProduct.location_selected = {
                    locationID: 0,
                    locationName: '',
                    locationShortName: datasetProduct.rackNo
                  }
                }
              }
            }
            
            this.importExportDetail.splice(0, 0, datasetProduct);
           
            
            this.savechanges = true;
          }
        }
        this.loading=false;
        this.addStockPopup.dialog = false;
        this.SumQuantity();
        if (AlertProductDuplicate != "") {
          Swal.fire({
            icon: "warning",
            title: "ไม่สามารถทำรายการได้",
            text: AlertProductDuplicate + " มีรายการมากกว่า 1 รายการ",
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
          });
        }
      } catch (error) {
        this.loading=false;
        Swal.fire({
          icon: "warning", 
          text: "ไม่สามารถทำรายการได้ กรุณาทำอีกครั้ง" 
        });
      }
     
    },
    async Submit_addStockExcelPopup() {
      if (this.submittingProductFromExcel) {
        return;
      }
      this.submittingProductFromExcel = true;

      // this.addStockPopup.loading = true;
      // this.addStockExcelPopup.loading = true;
      // this.addStockExcelPopup.dataset = await this.OrderBy(
      //   this.addStockExcelPopup.dataset
      // );
      var sp = this.addStockExcelPopup.dataset;
      var DetailItem = [];
      for (var i in sp) {
        var datasetProduct = {};
        if (this.importExportMaster.transactionType == 1) {
          var mfgdate = null;
          var expdate = null;
          if (sp[i].expiryDate !== null && sp[i].expiryDate !== "") {
            const [day, month, year] = sp[i].expiryDate.split("/");
            expdate = year + "-" + month + "-" + day;
          }
          if (sp[i].mfgDate !== null && sp[i].mfgDate !== "") {
            const [day, month, year] = sp[i].mfgDate.split("/");
            mfgdate = year + "-" + month + "-" + day;
          }

          datasetProduct = {
            id: 0,
            importExportMasterId: this.importExportMaster.id,
            productMasterItemId: sp[i].id,
            stockShopId: sp[i].stockShopId,
            stockShopItemId: null,
            quantity: sp[i].quantity,
            quantityRequest: sp[i].quantity,
            costPrice: sp[i].costPrice,
            rackNo: sp[i].rackNo,
            remark: sp[i].remark,
            created: null,
            mfgDate: mfgdate,
            expiryDate: expdate,
            photoLink: sp[i].photoLink,
            prop1Description: sp[i].prop1Description,
            prop2Description: sp[i].prop2Description,
            sku: sp[i].sku,
            barcode: sp[i].barcode,
            name: sp[i].name,
          };
        } else {
          datasetProduct = {
            id: 0,
            importExportMasterId: sp[i].importExportMasterId,
            productMasterItemId: sp[i].id,
            stockShopId: null,
            stockShopItemId: null,
            photoLink: sp[i].photoLink,
            name: sp[i].name,
            prop1: sp[i].prop1,
            prop1Description: sp[i].prop1Description,
            prop2: sp[i].prop2,
            prop2Description: sp[i].prop2Description,
            productCode: sp[i].productCode,
            sku: sp[i].sku,
            barcode: sp[i].barcode,
            unitPrice: sp[i].unitPrice,
            costPrice: sp[i].costPrice,
            quantity: sp[i].quantity,
            quantityRequest: sp[i].quantity,
            rackNo: sp[i].rackNo,
            remark: sp[i].remark,
            mfgDate: sp[i].mfgDate,
            expiryDate: sp[i].expiryDate,
            createdDatetime: sp[i].createdDatetime,

            expirationDateMenu: false,
          };
          this.IsMarkLot = "1";
        }
        DetailItem.push(datasetProduct);
      }
      this.submittingPercent = 0;

      if (this.importExportMaster.id == 0) {
        let UpdateMasterResponse = await axios.post(
          stockService_dotnet + "Stock/update-import-export-master-v2",
          this.importExportMaster,
          { headers: this.header_token }
        );
        if (
          UpdateMasterResponse.status == 200 &&
          UpdateMasterResponse.data.status == "success"
        ) {
          this.importExportMaster.id = UpdateMasterResponse.data.importExportMasterID;
        }
      }

      var length = DetailItem.length;
      let TotalRound = DetailItem.length;

      for (var CurrentIndex = 0; CurrentIndex < DetailItem.length; CurrentIndex++) {
        let IsPass = false;
        while (!IsPass) {
          try {
            DetailItem[CurrentIndex].importExportMasterId = this.importExportMaster.id;

            let UpdateDetailResponse = await axios.post(
              stockService_dotnet + "Stock/update-import-export-detail-v2",
              DetailItem[CurrentIndex],
              { headers: this.header_token }
            );

            if (
              UpdateDetailResponse.status == 200 &&
              UpdateDetailResponse.data.status == "success"
            ) {
              DetailItem.id = UpdateDetailResponse.data.importExportDetailID;
              this.submittingPercent = format_price(
                ((CurrentIndex + 1) / TotalRound) * 100
              );
              IsPass = true;
              this.savechanges = true;
            }
          } catch (e) {
            // error
          }
        }
      }

      this.submittingProductFromExcel = false;

      this.addStockExcelPopup.loading = false;
      this.addStockExcelPopup.dialog = false;
      await this.GetImportExportMaster(this.importExportMaster.id);
      this.SumQuantity();
      this.$refs.file_excel.reset();
    },
    async OrderBy(data) {
      var dataset = [];
      for (var i = data.length - 1; i >= 0; i--) {
        dataset.push(data[i]);
      }
      return dataset;
    },
    async CreateReturnParcel() {
      if (
        this.importExportMaster.referenceInfo != "" ||
        this.importExportMaster.referenceInfo != null
      ) {
        this.loading = true;
        try {
          var ordermaster = null;
          var trackingNo = null;
          if (
            this.importExportMaster.referenceInfo.substring(0, 2).toUpperCase() == "PA"
          ) {
            ordermaster = this.importExportMaster.referenceInfo;
          } else {
            trackingNo = this.importExportMaster.referenceInfo;
          }

          let response = await axios.post(
            stockService_dotnet + "Stock/create-return-parcel",
            {
              orderMasterID: ordermaster,
              trackingNo: trackingNo,
              isUpdateToMarketplace:
                this.importExportMaster.isUpdateToMarketplace == "true" ? true : false,
              staffBranchId: parseInt(localStorage.getItem("Branch_StaffID")),
              staffShopId: null,
              warehouseId: this.importExportMaster.toWarehouseId,
            },
            { headers: this.header_token }
          );
          this.loading = false;
          if (response.status == 200 && response.data.status == "success") {
            await this.GetImportExportMaster(response.data.importExportMasterId);
          } else {
            Swal.fire({
              icon: "warning",
              text: response.data.message[this.language],
            });
          }
        } catch (e) {
          this.loading = false;
          this.AlertError();
          // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
          this.SendError(
            "warehouse",
            "stock",
            "SearchProductSendBack",
            this.BranchID,
            null,
            null,
            e,
            null
          );
        }
      } else {
        Swal.fire({
          type: "warning",
          text: "กรุณาระบุเลขออเดอร์ / TrackingNo",
        });
      }
    },
    SetReferenceInfo(ReferenceInfo) {
      if (ReferenceInfo != null)
        return this.ChangeReferenceInfo(ReferenceInfo).toString();
      return null;
    },

    // เอกสาร
    async Add_ImportExportPhoto() {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      try {
        this.savechanges = true;
        let response = await axios.post(
          stockService_dotnet + "Stock/add-importexportphoto",
          {
            Id: this.importExportMaster.id,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          if (response.data.status && response.data.message == null) {
            var data = response.data.importExportPhotoList;
            if (data.length > 0) {
              this.importExportPhoto.push({
                id: data[0].id,
                importExportMasterId: data[0].importExportMasterId,
                fileUrl: null,
                fileUpdate: {
                  file: [],
                  file_url: null,
                  file_name: null,
                  file_size: 0,
                  from_data: {},
                  from_path: "",
                },
              });
            }
          } else {
            // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
            this.SendError(
              "warehouse",
              "stock",
              stockService_dotnet + "Stock/add-importexportphoto",
              this.BranchID,
              null,
              null,
              response.data.description,
              response.data.message
            );
          }
        } else {
          // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
          this.SendError(
            "warehouse",
            "stock",
            stockService_dotnet + "Stock/add-importexportphoto",
            this.BranchID,
            null,
            null,
            null,
            null
          );
        }
      } catch (e) {
        // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
        this.SendError(
          "warehouse",
          "stock",
          "Add_ImportExportPhoto",
          this.BranchID,
          null,
          null,
          e,
          null
        );
      }
    },
    async previewpicture(index, event) {
      var file = event.target.files[0];
      if (file != undefined) {
        // เก็บภาพเดิมที่มีเอาไปลบออก
        if (
          this.importExportPhoto[index].id > 0 &&
          this.importExportPhoto[index].fileUrl != "" &&
          this.importExportPhoto[index].fileUrl != null
        ) {
          var pathRemove = this.importExportPhoto[index].fileUrl;

          let response = await axios.post(
            generalService_dotnet + "General/delete-file",
            {
              url: pathRemove,
            },
            { headers: this.header_token }
          );
          if (response.status == 200) {
            await this.Update_ImportExportPhoto(index, null);
          }
        }

        // เช็ตข้อมูลก่อนอัพภาพ
        this.importExportPhoto[index].fileUpdate.file = file;
        var file_type = file.type.split("/");
        var from_data = new FormData();

        from_data.append("bucket", "stock");
        from_data.append("file", file);
        this.importExportPhoto[index].fileUpdate.from_data = from_data;

        // อัพโหลดภาพ
        let UploadPicResponse = await axios.post(
          generalService_dotnet + "General/upload-file-with-bucket",
          this.importExportPhoto[index].fileUpdate.from_data,
          { headers: this.header_token }
        );
        if (UploadPicResponse.status == 200) {
          this.importExportPhoto[index].fileUpdate.from_path =
            UploadPicResponse.data.file_path;
          var filenme = UploadPicResponse.data.file_path.split(/(\\|\/)/g).pop();

          await this.Update_ImportExportPhoto(
            index,
            this.importExportPhoto[index].fileUpdate.from_path
          );
          this.importExportPhoto[index].fileUpdate.file_name = filenme;
        }
      }
    },
    async Update_ImportExportPhoto(index, fileUrl) {
      try {
        var importexportPhoto = {
          Id: this.importExportPhoto[index].id,
          ImportExportMasterId: this.importExportPhoto[index].importExportMasterId,
          FileUrl: fileUrl,
        };
        this.savechanges = true;
        let response = await axios.post(
          stockService_dotnet + "Stock/update-importexportphoto",
          {
            importExportPhoto: importexportPhoto,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          if (response.data.status && response.data.message == null) {
            this.importExportPhoto[index].fileUrl = fileUrl;
          } else {
            // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
            this.SendError(
              "warehouse",
              "stock",
              stockService_dotnet + "Stock/update-importexportphoto",
              this.BranchID,
              null,
              null,
              response.data.description,
              response.data.message
            );
          }
        } else {
          // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
          this.SendError(
            "warehouse",
            "stock",
            stockService_dotnet + "Stock/update-importexportphoto",
            this.BranchID,
            null,
            null,
            null,
            null
          );
        }
      } catch (e) {
        // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
        this.SendError(
          "warehouse",
          "stock",
          "Update_ImportExportPhoto",
          this.BranchID,
          null,
          null,
          e,
          null
        );
      }
    },
    async Del_ImportExportPhoto(index, item) {
      Swal.fire({
        position: "top",
        icon: "warning",
        title: "ต้องการลบใช่หรือไม่",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          // ลบ details
          if (item.id > 0) {
            try {
              this.savechanges = true;
              let Remove = await axios.post(
                stockService_dotnet + "Stock/delete-importexportphoto",
                {
                  Id: item.id,
                },
                { headers: this.header_token }
              );
              if (Remove.status == 200) {
                if (Remove.data.status && Remove.data.message == null) {
                  this.importExportPhoto.splice(index, 1);
                } else {
                  // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
                  this.SendError(
                    "warehouse",
                    "stock",
                    stockService_dotnet + "Stock/delete-importexportphoto",
                    this.BranchID,
                    null,
                    null,
                    Remove.data.description,
                    Remove.data.message
                  );
                }
              } else {
                // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
                this.SendError(
                  "warehouse",
                  "stock",
                  stockService_dotnet + "Stock/delete-importexportphoto",
                  this.BranchID,
                  null,
                  null,
                  null,
                  null
                );
              }
            } catch (e) {
              // (platForm,fromPath,fromApi,branchId,shopId,orderId,logInfo,message)
              this.SendError(
                "warehouse",
                "stock",
                "Del_ImportExportPhoto",
                this.BranchID,
                null,
                null,
                e,
                null
              );
            }
          } else {
            this.importExportPhoto.splice(index, 1);
          }
        }
      });
    },

    // save approved
    async CheckSaveChanges() {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      var IsValid = await this.WorkSaveChanges();
      if (IsValid == true) {
        this.SuccessSaveChanges();
      }
    },

    async ApprovedImportExport() {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      var IsValid = await this.WorkSaveChanges();
      if (IsValid == false) {
        return;
      }

      try {
        this.isApproveDisabled = true;
        var currentTransaction = 1;
        var TotalTransaction = this.importExportDetail.length + 1;
        this.approvingPercent = parseInt((currentTransaction / TotalTransaction) * 100);

        let dataResponse = await axios.get(
          stockService_dotnet +
          "Stock/get-import-export?ID=" +
          this.importExportMaster.id +
          "&isAscending=true",
          { headers: this.header_token }
        );

        var currentImportExportDetail = dataResponse.data.importExportDetail;
        TotalTransaction = currentImportExportDetail.length + 1;

        for (let item in currentImportExportDetail) {
          var IsPass = false;
          while (!IsPass) {
            let approved = await axios.post(
              stockService_dotnet + "Stock/approved-importexport",
              {
                importExportMasterId: this.importExportMaster.id,
                importExportDetailIdList: [currentImportExportDetail[item].id],
                approvedStaffBranchId: this.staffBranchID,
              },
              { headers: this.header_token }
            );

            if (approved.status == 200 && approved.data.status == "success") {
              currentTransaction = currentTransaction + 1;
              this.approvingPercent = parseInt(
                (currentTransaction / TotalTransaction) * 100
              );
              IsPass = true;
            }
          }
        }

        this.AlertSuccess("อนุมัติเรียบร้อยแล้ว");
        setTimeout(() => {
          this.$router.push("/transfer-list");
        }, 1000);
      } catch (e) {
        this.isApproveDisabled = false;
        this.approvingPercent = 0;
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้",
          text: "กรุณาทำรายการอีกครั้ง",
        });
      }
    },
    checkBeforeApprove() {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      var result = "";
      for (var i of this.importExportDetail) {
        
        
        if (i.rackNo == null || i.rackNo == '') {
          result = ", " + i.no + result;
        }
      }
      if (result != "" && this.BranchInfo.isForceLocation &&this.importExportMaster.transactionType==1) {
        Swal.fire({
          icon: "error",
          text: "รายการที่ " + result + " ยังไม่ได้ระบุ Location, กรุณาระบุ",
        });
        return;
      }
   

      result="";
      for (  i of this.importExportDetail) {
        if (i.quantity == 0 || i.quantity == null) {
          result = ", " + i.no + result;
        }
      }

      if (result != "") {
        result = result.substring(1).trim();

        Swal.fire({
          icon: "question",
          title: "มีบางรายการยังไม่ได้ระบุจำนวน",
          text: "รายการที่ " + result + " ยังไม่ได้ระบุจำนวน กรุณากด 'ยืนยัน' หากต้องการทำรายการต่อ",
          confirmButtonText: "ยืนยันอนุมัติ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.value) {
            this.approveDialog = true;
          }
        });
      }else{
        this.approveDialog = true;
      }

    },
    async WorkSaveChanges() {
      var result = "";

      // for (var i of this.importExportDetail) {
      //   if (i.quantity == 0 || i.quantity == null) {
      //     result = ", " + i.no + result;
      //   }
      // }

      if (this.importExportMaster.transactionType == 3) {
        var product = this.importExportDetail.filter((x) => x.quantity > 0);
        if (product.length > 0) {
          result = "";
        }
      }

      if (result != "") {
        result = result.substring(1).trim();
        Swal.fire({
          icon: "warning",
          title: "ไม่สามารถทำรายการได้",
          text: "รายการที่ " + result + " ต้องระบุจำนวนที่มากกว่า 0",
          showConfirmButton: true,
          confirmButtonText: "เช็คอีกครั้ง",
        });
        return false;
      }


      if (this.importExportDetail.filter((x) => x.isUpdated).length > 0) {
        this.saveDialog = true;
        this.savingPercent = 0;
        var currentTransaction = 1;
        var TotalTransaction = this.importExportDetail.length + 1;
        this.savingPercent = parseInt((currentTransaction / TotalTransaction) * 100);




        for (let item of this.importExportDetail) {
          if (item.isUpdated) {
            var IsPass = false;
            while (!IsPass) {
              let update = await axios.post(
                stockService_dotnet + "Stock/update-import-export-detail-v2",
                {
                  id: item.id,
                  importExportMasterId: item.importExportMasterId,
                  productMasterItemId: item.productMasterItemId,
                  stockShopId: item.stockShopId,
                  stockShopItemId: item.stockShopItemId,
                  quantityRequest: item.quantityRequest,
                  quantity: item.quantity,
                  costPrice: item.costPrice,
                  rackNo: item.rackNo,
                  locationID: item.locationID,
                  mfgDate: item.mfgDate,
                  expiryDate: item.expiryDate,
                  remark: item.remark,
                },

                { headers: this.header_token }
              );

              if (update.status == 200 && update.data.status == "success") {



                IsPass = true;
                item.isUpdated = false;
              }
            }
          }

          currentTransaction = currentTransaction + 1;
          this.savingPercent = parseInt(
            (currentTransaction / TotalTransaction) * 100
          );
        }
        setTimeout(() => {
          this.saveDialog = false;
        }, 1000);






      }

      return true;
    },
    SuccessSaveChanges() {
      this.savechanges = false;
      this.AlertSuccess("บันทึกสำเร็จ");
    },

    // ปริ้น ฺBarcode
    async OPPrintBarcode(item) {
      if (item.barcode == null || item.barcode == "") {
        try {
          this.loading = false;
          let result = await axios.get(
            stockService_dotnet +
            "Stock/generate-barcode?productmasteritemid=" +
            item.productMasterItemId.toString(),
            { headers: this.header_token }
          );
          this.loading = false;
          if (result.status == 200 && result.data.status == "success") {
            item.barcode = result.data.barcode;
          } else {
            Swal.fire({
              type: "error",
              text: result.data.message[this.language],
            });
          }
        } catch {
          this.loading = false;
        }
      }

      if (item.barcode != null && item.barcode != "") {
        item.lotNo = "lot" + item.id.toString();
        item.lotName = this.importExportMaster.referenceNumber;
        this.printbarcodeitem.dialog = true;
        this.printbarcodeitem.item = item;
      }
    },
    CPrintBarcode(value) {
      this.printbarcodeitem.dialog = value;
    },

    // เรียกใช้
    ClosePhoto(value) {
      this.ShowPhoto = value;
      this.ShowPhotoLink = "";
    },
    SumQuantity() {
      this.QuantityReceived = 0;
      this.QuantityToReceive = 0;
      for (var i of this.importExportDetail) {
        this.QuantityReceived += ((i.quantity == null || i.quantity == "") ? 0 : parseInt(i.quantity));
        this.QuantityToReceive += ((i.quantityRequest == null || i.quantityRequest == "") ? 0 : parseInt(i.quantityRequest));
      }

    },


    CheckDisabledTransaction() {
      var res = false;
      if (this.transaction.id == 3) {
        if (
          this.importExportMaster.referenceInfo != null &&
          this.importExportMaster.referenceInfo != ""
        ) {
          res = true;
        }
      } else {
        if (this.warehouse_from_selected.id > 0 || this.warehouse_to_selected.id > 0) {
          res = true;
        }
      }
      return res;
    },
    SetIndex(index) {
      this.indexDoc = index;
    },

    Check_Headers_Product(type) {
      type = parseInt(type);
      if (type == 1) {
        this.headerProduct = this.headerProductIn;
      } else if (type == 2) {
        this.headerProduct = this.headerProductOut;
      } else if (type == 4) {
        this.headerProduct = this.headerProductTransfer;
      } else if (type == 3) {
        this.headerProduct = this.headerProductSendback;
      } else if (type == 5) {
        this.headerProduct = this.headerProductIn;
      }
    },

    async Check_Headers_Select_Product(type) {
      type = parseInt(type);
      if (type == 1 || type == 5) {
        this.addStockPopup.header = this.addStockPopup.headerIn;
      } else if (type == 2 || type == 4) {
        this.addStockPopup.header = this.addStockPopup.headerOut;
        if (this.IsMarkLot == 1) {
          this.addStockPopup.header = this.addStockPopup.headerOutNoLot;
        }
      }
    },
    async Check_Transfer() {
      if (this.importExportMaster.transactionType == 4) {
        // โอนเข้าคลังเดิมwไม่ได้
        if (
          this.warehouse_from_selected.id != 0 &&
          this.warehouse_to_selected.id != 0 &&
          this.warehouse_from_selected.id == this.warehouse_to_selected.id
        ) {
          Swal.fire({
            toast: true,
            timer: 1500,
            position: "top",
            icon: "warning",
            title: "ถ่ายโอนสินค้า เข้าคลังเดิมไม่ได้",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });

          await this.Clear_Warehouse();
          this.IsTransferValid = false;
        }
      }
    },
    Clear_Warehouse() {
      this.warehouse_from_selected = JSON.parse(JSON.stringify(this.warehouseList[0]));

      this.warehouse_to_selected = JSON.parse(JSON.stringify(this.warehouseList[0]));
    },
    MarkLotChange() {
      if (this.IsMarkLot == "1") {
        this.addStockPopup.header = this.addStockPopup.headerOutNoLot;
      } else {
        this.addStockPopup.header = this.addStockPopup.headerOut;
      }
      this.PopupsearchStock(this.limits, 0);
    },
    TransactionTypeCheckChanged() {
      this.IsSendBack = false;
      this.shop = { id: 0, name: "--เลือกร้านค้า--" };
      this.importExportMaster.shopId = 0;
      this.warehouse_to_selected = this.warehouseList[0];
      this.warehouse_from_selected = this.warehouseList[0];
      this.importExportMaster.toWarehouseId = null;
      this.importExportMaster.fromWarehouseId = null;

      this.IsImportValid = false;
      this.IsExportValid = false;
      this.IsReturnValid = false;
      this.IsTransferValid = false;
      this.IsAdjustValid = false;

      if (this.importExportMaster.transactionType == 1) {
        if (this.warehouseList.length == 2) {
          this.warehouse_to_selected = this.warehouseList[1];
          this.importExportMaster.toWarehouseId = this.warehouse_to_selected.id;
        }
      } else if (this.importExportMaster.transactionType == 2) {
        if (this.warehouseList.length == 2) {
          this.warehouse_from_selected = this.warehouseList[1];
          this.importExportMaster.fromWarehouseId = this.warehouse_from_selected.id;
        }
      } else if (this.importExportMaster.transactionType == 3) {
        if (this.warehouseList.length == 2) {
          this.warehouse_to_selected = this.warehouseList[1];
          this.importExportMaster.toWarehouseId = this.warehouse_to_selected.id;
          this.IsReturnValid = true;
        }

        this.IsSendBack = true;
        this.ckeckWarehouse = true;
      } else if (this.importExportMaster.transactionType == 4) {
        //nothing
        if (this.warehouseList.length == 2) {
          this.warehouse_from_selected = this.warehouseList[1];
          this.importExportMaster.fromWarehouseId = this.warehouse_from_selected.id;
        }
      }
      this.Check_Headers_Product(this.importExportMaster.transactionType);
      this.Check_Headers_Select_Product(this.importExportMaster.transactionType);

      this.CheckInfoFirtNeed();
    },
    shop_changed() {
      this.importExportMaster.shopId = this.shop.id;
      this.CheckInfoFirtNeed();
    },
    shop_transfer_changed() {
      if (this.shop.id > 0) {
        this.importExportMaster.shopId = this.shop.id;
        this.getShopWarehouseList(this.shop.id);
      }

      this.CheckInfoFirtNeed();
    },
    warehouse_to_selected_changed() {
      if (this.warehouse_to_selected.id == 0) {
        if (this.importExportMaster.transactionType != 4) {
          this.importExportMaster.shopId = 0;
        }

        this.importExportMaster.toWarehouseId = null;
        this.IsReturnValid = false;
        this.IsTransferValid = false;
      } else {
        this.importExportMaster.toWarehouseId = this.warehouse_to_selected.id;
      }

      this.CheckInfoFirtNeed();
    },
    CheckLocationSelected(val) {
      // do nothing


      if (typeof val === 'object' && val != null) {
        this.all_location_selected = val;
        this.checkRackInputCounter = 1;
      } else {
        if (val) {

          this.all_location_selected = {
            locationID: 0,
            locationName: val,
            locationShortName: val

          }
        } else {
          this.all_location_selected = null;

        }
        this.checkRackInputCounter = 1;
        this.SaveRackNoAll();
      }


    },
    SaveRackNoAll() {
      if (!this.isBtnSaveAllRackPressed) {
        return;
      }
      if (this.all_location_selected || this.allRackNoInput) {
        if (this.allRackNoInput) {
          //
          for (let index = this.importExportDetail.length - 1; index >= 0; index--) {
            this.importExportDetail[index].rackNo = this.allRackNoInput;
            this.importExportDetail[index].locationID = null;
            this.importExportDetail[index].isUpdated = true;
            this.savechanges = true;
          }
          this.chooseRackNoDialog = false;
        }
        else if (this.all_location_selected != null) {
          for (let index = this.importExportDetail.length - 1; index >= 0; index--) {
            this.importExportDetail[index].rackNo = this.all_location_selected.locationShortName;
            this.importExportDetail[index].locationID = this.all_location_selected.locationID;
            this.importExportDetail[index].location_selected = this.all_location_selected;
            this.importExportDetail[index].isUpdated = true;
            this.savechanges = true;
          }


          this.chooseRackNoDialog = false;
        }


      } else {
        this.alertRackInput = true;
        return;
      }

    },
    btnSaveRackNoAll() {
      this.isBtnSaveAllRackPressed = true;
      if (this.location_list.length > 0) {

        if (this.checkRackInputCounter == 0)
          return;
      }
      else if (!this.allRackNoInput) {
        this.alertRackInput = true;
        return;
      }
      this.SaveRackNoAll();




    },
    warehouse_from_selected_changed() {
      if (this.warehouse_from_selected.id == 0) {
        this.importExportMaster.fromWarehouseId = null;
      } else {
        this.importExportMaster.fromWarehouseId = this.warehouse_from_selected.id;
      }
      this.CheckInfoFirtNeed();
    },
    CheckInfoFirtNeed() {
      if (
        this.importExportMaster.transactionType == 1 &&
        this.warehouse_to_selected.id > 0 &&
        this.importExportMaster.shopId > 0
      ) {
        this.ckeckWarehouse = true;
        this.IsImportValid = true;
      } else if (
        this.importExportMaster.transactionType == 2 &&
        this.warehouse_from_selected.id > 0 &&
        this.importExportMaster.shopId > 0
      ) {
        this.ckeckWarehouse = true;
        this.IsExportValid = true;
      } else if (
        this.importExportMaster.transactionType == 3 &&
        this.warehouse_to_selected.id > 0
      ) {
        this.ckeckWarehouse = true;
        this.IsReturnValid = true;
      } else if (
        this.importExportMaster.transactionType == 4 &&
        this.warehouse_to_selected.id > 0 &&
        this.warehouse_from_selected.id > 0 &&
        this.importExportMaster.shopId > 0
      ) {
        this.IsTransferValid = true;
      } else if (
        this.importExportMaster.transactionType == 5 &&
        this.warehouse_to_selected.id > 0 &&
        this.importExportMaster.shopId > 0
      ) {
        this.IsAdjustValid = true;
      } else {
        this.ckeckWarehouse = false;
      }
    },

    async changePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        var offsets = this.page * this.limits - this.limits;
        await this.getSelectProduct(this.limits, offsets, this.page, this.popupsearch);
        this.pageTemp = this.page;
      }
    },
    async OpenExcelPopup() {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      this.tab = 0;
      this.submittingPercent = 0;
      this.addStockExcelPopup.dataset = [];
      this.addStockExcelPopup.dialog = true;
    },

    async get_Product_excel() {
      if (this.file_excel.length == 0) {
        this.$refs.file_excel.focus();
        return;
      }

      this.addStockExcelPopup.dataset = [];
      this.addStockExcelPopup.dataset_null = [];
      this.addStockExcelPopup.dataset_failed = [];
      this.addStockExcelPopup.count_successes = 0;
      this.addStockExcelPopup.count_failed = 0;
      this.addStockExcelPopup.Total_Round = 0;
      this.addStockExcelPopup.count_null = 0;
      this.addStockExcelPopup.status = true;
      let amount_of_data_each = 10; // หลังคือจำนวนที่ต้องการวนข้อมูลจำนวน
      let importStockBody = await this.excel_to_object(); //ผ่าน แปลงข้อมูลเป็น object
      if (importStockBody.length == 0) {
        this.addStockExcelPopup.status = false;
        return;
      }

      this.addStockExcelPopup.Total_Round =
        Math.ceil(importStockBody.length / amount_of_data_each) + 2; // หาจำนวนที่ต้องวนข้อมูล
      this.addStockExcelPopup.count_successes++;
      let object_fetch_api = await this.extraction_row_to_object(
        importStockBody,
        this.addStockExcelPopup.Total_Round - 2,
        amount_of_data_each
      ); //ผ่าน หั่นข้อมูลเป็น ชิ้นๆ ตามจำนวน amount_of_data_each

      let loop_api = await this.for_fetch_api(object_fetch_api); //วนยิง  API

      this.addStockExcelPopup.count_successes++;

      let log_error = await this.object_to_data_table(loop_api); //เอาข้อมูลไปใส่ในData table และ แยกเอา log error ออกมา

      this.addStockExcelPopup.status = false;
    },

    async setDateExcelToDateFrom(date, formatDate) {
      let date_Format = date;
      try {
        if (typeof date == "object") {
          date_Format = date.toLocaleDateString();
        }

        const [day, month, year] = date_Format.split("/");
        const date_res = new Date(+year, month - 1, +day);

        if (day == undefined || month == undefined || year == undefined) {
          if (formatDate == "toLocaleDateString") {
            return null;
          } else {
            return null;
          }
        } else {
          if (formatDate == "toLocaleDateString") {
            return date_Format;
          } else {
            return date_res.toISOString().substring(0, 10);
          }
        }
      } catch {
        return null;
      }
    },
    async excel_to_object() {
      let importStockBody = [];
      let Ispass = false;
      await readXlsxFile(this.file_excel,{ dateFormat: 'yyyy-MM-dd' }).then(async (rows) => {
       
        
        this.addStockExcelPopup.count = rows.length - 1;
        //if (rows.length <= 51) {
          console.log(' rows.length', rows[2][4]);
        try {
      
        
          for (var i in rows) {
            if (i > 0) {
              var mfgdate = null,
                expirydate = null;
              var realMfgDate = null,
                realExpiryDate = null;
 
              

              if (rows[i][4] != "" && rows[i][4] != null&& rows[i][4] != 'NA') {
                mfgdate = rows[i][4];
                if(mfgdate.toString().length==5){
                  date = new Date(1900, 0, 1) 
                  date.setDate(date.getDate() + parseInt(mfgdate))
                  mfgdate = `${date.getDate()}/${date.getMonth() + 1 }/${date.getFullYear()}`;
                  realMfgDate = date;
                }else{

             
                  var date = new Date(mfgdate); //ต้องเแปลงเป็น Date หากมีค่าnull || undefindจะได้ไม่error
                  if(!isNaN(date)){
                    const formatmfgdate = `${date.getDate()}/${date.getMonth() + 1
                      }/${date.getFullYear()}`;
                    if (formatmfgdate.includes("/")) {
                      var tempdata = formatmfgdate.split("/");
                      mfgdate = tempdata[0] + "/" + tempdata[1] + "/" + tempdata[2];
                      realMfgDate = new Date(tempdata[0], tempdata[1], tempdata[2]);
                    }
                  }
                }
              }
              if (rows[i][5] != "" && rows[i][5] != null&& rows[i][5] != 'NA') {
                expirydate = rows[i][5];
                if(expirydate.toString().length==5){
                  date = new Date(1900, 0, 1) 
                  date.setDate(date.getDate() + parseInt(expirydate))
                  expirydate = `${date.getDate()}/${date.getMonth() + 1 }/${date.getFullYear()}`;
                  realExpiryDate = date;
                }else{

                  var dateEx = new Date(expirydate);
                  if(!isNaN(dateEx)){
                    const formatexpirydate = `${dateEx.getDate()}/${dateEx.getMonth() + 1
                      }/${dateEx.getFullYear()}`;
                    if (formatexpirydate.includes("/")) {
                      var tempdata1 = formatexpirydate.split("/");
                      expirydate = tempdata1[0] + "/" + tempdata1[1] + "/" + tempdata1[2];
                      realExpiryDate = new Date(tempdata1[0], tempdata1[1], tempdata1[2]);
                    }
                  }
                }
              }
              var quantity = 1;
              if (rows[i][3] === null || rows[i][3] === "") {
                quantity = 1;
              } else {
                quantity = parseInt(rows[i][3]);
                if (quantity == 0) quantity = 1;
              }

 

              importStockBody.push({
                sku: rows[i][0] == null ? "" : String(rows[i][0]),
                barcode: rows[i][1] == null ? "" : String(rows[i][1]),
                costPrice: rows[i][2],
                unitPrice: null,
                quantity: quantity,
                mfgDate: mfgdate,
                expiryDate: expirydate,
                manufactureDateNow: realMfgDate,
                manufactureDateStart: realMfgDate,
                expirationDateNow: realExpiryDate,
                expirationDateStart: realExpiryDate,

                // manufactureDateNow: await this.setDateExcelToDateFrom(
                //   realMfgDate,
                //   "toLocaleDateString"
                // ), //เอาไว้โชวฺ
                // expirationDateNow: await this.setDateExcelToDateFrom(
                //   realExpiryDate,
                //   "toLocaleDateString"
                // ), //เอาไว้โชวฺ
                // manufactureDateStart: await this.setDateExcelToDateFrom(
                //   realMfgDate,
                //   "toISOString"
                // ),
                // expirationDateStart: await this.setDateExcelToDateFrom(
                //   realExpiryDate,
                //   "toISOString"
                // ),
                rackNo: rows[i][6],
                remark: rows[i][7],
                no: i,
                row: i,
              });
              console.log(importStockBody);
                  
            }
            Ispass = true;
          }
          console.log(importStockBody);
        } catch (error) {
          Swal.fire({
            type: "warning",
            title: "ไม่สามารถเพิ่มรายการได้",
            text: "กรุณาตรวจสอบไฟล์ Excel",
          });
        }
        // } else {
        //   Swal.fire({
        //     type: "warning",
        //     title: "จำกัดไม่เกิน 50 รายการต่อครั้ง",
        //     text: "กรุณาแก้ไขรายการใหม่",
        //   });
        // }
        Ispass = false;
      });
      console.log(importStockBody);
      return importStockBody;
    },

    async extraction_row_to_object(importStockBody, loop_fetch_api_importExcel, total) {
      let importStock = importStockBody;
      let bodyOut = [];

      for (let i = 0; i <= loop_fetch_api_importExcel - 1; i++) {
        bodyOut[i] = importStock.splice(0, total);
      }
 
      
      return bodyOut;
    },
    async for_fetch_api(body) {
      let response_out = [];
      for (let i in body) {
        let response = await this.fetch_api_importExcel(body[i]);
        response_out.push(response);
      }
      return response_out;
    },
    async fetch_api_importExcel(importStockBody) {
      let IsPass = false;
      let outBody;
      while (!IsPass) {
        var WarehouseID = this.warehouse_to_selected.id;
        var ShopID = this.shop.id;

        if (
          this.importExportMaster.transactionType == 2 ||
          this.importExportMaster.transactionType == 4
        ) {
          WarehouseID = this.warehouse_from_selected.id;
        }
        if (this.importExportMaster.id != 0) {
          WarehouseID = this.importExportMaster.toWarehouseId;
          if (
            this.importExportMaster.transactionType == 2 ||
            this.importExportMaster.transactionType == 4
          ) {
            WarehouseID = this.importExportMaster.fromWarehouseId;
          }
          ShopID = this.importExportMaster.shopId;
        }
        await axios
          .post(
            stockService_dotnet + "Stock/get-import-product-list-excel",
            {
              branchId: this.BranchID,
              toWarehouseId: WarehouseID,
              shopId: ShopID,
              importStockBody: importStockBody,
            },
            { headers: this.header_token }
          )
          .then((res) => {
            this.addStockExcelPopup.count_successes++;
            var body = [];
            for (var i in res.data.resultData) {
              if (res.data.resultData[i] != null) {
                body[i] = res.data.resultData[i];
                body[i].costPrice = importStockBody[i].costPrice;
                body[i].unitPrice = importStockBody[i].unitPrice;
                body[i].quantity = importStockBody[i].quantity;
                body[i].mfgDate = importStockBody[i].mfgDate;
                body[i].expiryDate = importStockBody[i].expiryDate;
                body[i].manufactureDateStart = importStockBody[i].manufactureDateStart;
                body[i].manufactureDateNow = importStockBody[i].manufactureDateNow;
               
                
                body[i].expirationDateStart = importStockBody[i].expirationDateStart;
                body[i].expirationDateNow = importStockBody[i].expirationDateNow;
                body[i].rackNo = importStockBody[i].rackNo;
                body[i].remark = importStockBody[i].remark;
              } else {
                this.addStockExcelPopup.count_null++;
                this.addStockExcelPopup.dataset_null.push(importStockBody[i]);
              }
            }
            outBody = body;
            IsPass = true;
          })
          .catch(async (error) => { });
      }
      return outBody;
    },
    async object_to_data_table(object) {
      let body = object;
      let data_table = [];
      let message = [];

      message.message_duplicat = "";
      message.message_found = "";

      for (let i in body) {
        for (let a in body[i]) {
          if (a != "message_duplicat" && a !== "message_found") {
            data_table.push(body[i][a]);
          } else if (a == "message_duplicat") {
            message.message_duplicat = message.message_duplicat + body[i][a];
          } else if (a == "message_found") {
            message.message_found = message.message_found + body[i][a];
          }
        }
      }
      this.addStockExcelPopup.dataset = data_table;
      return message;
    },

    async PrepareExcel() {
      this.datasetExcel = [];
      var counter = this.importExportDetail.length;
      if (this.importExportMaster.transactionType == "1") {
        this.ExcelFile =
          "รายการนำเข้าสินค้าเลขที่ : " + this.GenIE(this.importExportMaster.id);
      } else if (this.importExportMaster.transactionType == "2") {
        this.ExcelFile =
          "รายการนำออกสินค้าเลขที่ : " + this.GenIE(this.importExportMaster.id);
      } else if (this.importExportMaster.transactionType == "3") {
        this.ExcelFile =
          "รายการนำเข้าสินค้าตีกลับเลขที่ : " + this.GenIE(this.importExportMaster.id);
      } else if (this.importExportMaster.transactionType == "4") {
        this.ExcelFile =
          "รายการโอนย้ายสินค้าเลขที่ : " + this.GenIE(this.importExportMaster.id);
      }
      for (var i in this.importExportDetail) {
        var tempdata = {};

        tempdata.id = this.GenIE(this.importExportDetail[i].importExportMasterId);
        tempdata.no = counter;
        tempdata.name = this.importExportDetail[i].name;
        tempdata.sku = this.importExportDetail[i].sku;
        tempdata.barcode = this.importExportDetail[i].barcode;
        tempdata.productcode = this.importExportDetail[i].productCode;
        tempdata.quantity = this.importExportDetail[i].quantity;
        tempdata.costprice = this.importExportDetail[i].costPrice;
        tempdata.rackNo = this.importExportDetail[i].rackNo;
        tempdata.created = formatDatetime1(this.importExportDetail[i].itemCreated);
        tempdata.mfg_date = this.formatDate(this.importExportDetail[i].mfgDate);
        tempdata.exp_date = this.formatDate(this.importExportDetail[i].expiryDate);
        tempdata.remark = this.importExportDetail[i].remark;
        tempdata.prop1 = this.importExportDetail[i].prop1;
        tempdata.prop1Description = this.importExportDetail[i].prop1Description;
        tempdata.prop2 = this.importExportDetail[i].prop2;
        tempdata.prop2Description = this.importExportDetail[i].prop2Description;
        tempdata.lotName = this.importExportDetail[i].lotName;

        counter--;
        //this.datasetExcel.unshift(tempdata);
      }
    },
    async ExportExcel() {
      this.datasetExcel = [];
      this.loadingExcel = 0;
      Swal.fire({
        icon: "info",
        text: "ใช้เวลาสักครู่กรุณารอจนกว่าจะเสร็จ",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          var limits = 50;
          var offsets = 0;
          this.loadingExcel = 1;

          var no = 0;
          for (var i = 1; i <= 1; i++) {
            var resultData = await this.GetExportExcel();

            // สร้าง object เก็บข้อมูลใน dataDetail ตาม id ที่ซ้ำกัน
            const dataDetailMap = {};
            resultData.dataDetail.forEach((item) => {
              if (!dataDetailMap[item.id]) {
                dataDetailMap[item.id] = [];
              }
              dataDetailMap[item.id].push(item);
            });
            // นำข้อมูลใน dataDetailMap มาใส่ใน resultData ตาม id ที่ตรงกับ resultData
            resultData.resultData.forEach((item) => {
              if (dataDetailMap[item.id]) {
                item.dataDetail = dataDetailMap[item.id];
              }
            });
            var data = resultData.resultData;
            for (var j in data) {
              var staffBranchName =
                data[j].staffBranchName != null && data[j].staffBranchName != ""
                  ? data[j].staffBranchName
                  : data[j].staffShopName != null && data[j].staffShopName != ""
                    ? data[j].staffShopName
                    : null;
              var status = data[j].approvedDatetime != null ? "อนุมัติ" : "รออนุมัติ";
              var approved =
                data[j].approvedStaffBranchName != null &&
                  data[j].approvedStaffBranchName != ""
                  ? data[j].approvedStaffBranchName
                  : data[j].approvedStaffShopName != null &&
                    data[j].approvedStaffShopName != ""
                    ? data[j].approvedStaffShopName
                    : null;

              for (var x in data[j].dataDetail) {
                let warehousename = data[j].toWarehouseName;
                if(data[j].transactionType==3){
                  warehousename = data[j].dataDetail[x].toWarehouseName;
                }
                no += 1;
                this.datasetExcel.push({
                  no: no,
                  id: this.GenIE(data[j].id),
                  createdDatetime: this.formatDatetime(data[j].createdDatetime),
                  shopName: data[j].shopName,
                  fromWarehouseName: data[j].fromWarehouseName,
                  toWarehouseName:  warehousename,
                  transactionName: data[j].transactionName,
                  status: status,
                  referenceNumber: data[j].referenceNumber,
                  lotName: data[j].lotName,
                  createdBy: staffBranchName,
                  approvedBy: approved,

                  approvedDatetime: this.formatDatetime(data[j].approvedDatetime),
                  remark: data[j].remark,
                  productname: data[j].dataDetail[x].name,
                  sku: data[j].dataDetail[x].sku,
                  barcode: data[j].dataDetail[x].barcode,
                  Rack: data[j].dataDetail[x].currentRackNo,
                  prop1Description: data[j].dataDetail[x].prop1Description,
                  prop2Description: data[j].dataDetail[x].prop2Description,
                  productCode: data[j].dataDetail[x].productCode,
                  styleCode: data[j].dataDetail[x].aP21_STYLE_CODE,
                  colourCode: data[j].dataDetail[x].aP21_COLOUR_CODE,
                  brand: data[j].dataDetail[x].brand,
                  quantityRequest:  data[j].dataDetail[x].quantityRequest,
                  quantity: data[j].dataDetail[x].quantity,
                  mfgDate: this.formatMFG(data[j].dataDetail[x].mfgDate),
                  expiryDate: this.formatMFG(data[j].dataDetail[x].expiryDate),
                  itemRemark: data[j].dataDetail[x].itemRemark,
                });
              }
            }
            this.loadingExcel = parseInt(
              (parseFloat(i) / parseFloat(1)) * parseFloat(100)
            );
            offsets = offsets + limits;
          }
          this.headerExcel[24].label="ที่ต้องรับคืน";
          this.headerExcel[25].label="รับคืนจริง";
          this.loadingExcel = this.datasetExcel.length == 0 ? 0 : 100;
          document.getElementById("GetExcel").click();
          this.loadingExcel = 0;
        }
      });
    },
    async GetExportExcel() {
      try {
        let response = await axios.post(
          stockService_dotnet + "Stock/get-importexportmaster-list",
          {
            importExportMasterIDList: this.importExportMaster.id.toString(),
            isNeedDetail: true,
            skip: 0,
            take: 1,
          },
          { headers: this.header_token }
        );
        if (response.status == 200) {
          return response.data;
        } else {
          return [];
        }
      } catch (e) {
        console.log("error");
      }
    },
    async handleClickOutside(item) {
      if (this.get_premission_manage(14) == false) {
        this.permission_alert()
        return
      }

      if (item.btn && typeof this[item.btn] === "function") {
        this[item.btn]();
      }
    },
    isDisabled() {
      if (this.row_selected.length > 0) {
        return false;
      } else {
        return true; // Disable the item
      }
    },
    formatMFG(datetime) {
      if (datetime == null || datetime == "") {
        return null;
      }
      return (
        datetime.substring(0, 4) +
        "-" +
        datetime.substring(5, 7) +
        "-" +
        datetime.substring(8, 10)
      );
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },
    toBack(path) {
      this.$router.push("/" + path);
    },
    formatDate(date) { 
      
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    formatDate_thai,
    formatDatetime,
    formatDatetime1,
    formatMoney,
    isNumberWithDot,
    isNumberWNoDot,
    DateNowNotNull,
    CheckUrlPhoto,
    GenIE,
    Trim_value,
    Trim_rules,
    ChangeReferenceInfo,
    formatMoneyIsDecimal,
    AlertSuccess,
    AlertWarning,
    AlertError,
    SendError,
    getLocation,
    format_number,
    get_premission_manage,
    permission_alert
  },
};
</script>
<style>
.v-simple-checkbox {
  text-align: center !important;
}
</style>
<style scoped>
>>>.vue-barcode-element {
  width: 100% !important;
}

>>>.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  padding: 0 8px;
}

>>>.vue-avatar-cropper-demo {
  max-width: 18em;
  margin: 0 auto;
}

>>>.avatar {
  width: 160px;
  border-radius: 6px;
  display: block;
  margin: 20px auto;
}

.tabs {
  overflow: hidden;
  margin-bottom: -2px;
}

.tabs ul {
  list-style-type: none;
  margin-left: 20px;
}

.tabs a {
  float: left;
  cursor: pointer;
  padding: 12px 0px;
  transition: background-color 0.2s;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  width: 15%;
  text-align: center;
}

.tabs a:last-child {
  border-right: 1px solid #ccc;
}

/* Change background color of tabs on hover */
.tabs a:hover {
  background-color: #aaa;
  color: #fff;
}

/* Styling for active tab */
.tabs a.active {
  background-color: #fff;
  border-bottom: 2px solid #fff;
  cursor: default;
}

.tabcontent {
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 0px 10px 10px 10px;
  box-shadow: 3px 3px 6px #e1e1e1;
}

a:link {
  text-decoration: none;
}
</style>
